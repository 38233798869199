var buildHTML = require("./buildHTML");
var buildMathML = require("./buildMathML");
var buildCommon = require("./buildCommon");
var Options = require("./Options");
var Settings = require("./Settings");
var Style = require("./Style");
var makeSpan = buildCommon.makeSpan;
var buildTree = function (tree, expression, settings) {
  settings = settings || new Settings({});
  var startStyle = Style.TEXT;
  if (settings.displayMode) {
    startStyle = Style.DISPLAY;
  }

  // Setup the default options
  var options = new Options({
    style: startStyle,
    size: "size5"
  });

  // `buildHTML` sometimes messes with the parse tree (like turning bins ->
  // ords), so we build the MathML version first.
  var mathMLNode = buildMathML(tree, expression, options);
  var htmlNode = buildHTML(tree, options);
  var katexNode = makeSpan(["katex"], [mathMLNode, htmlNode]);
  if (settings.displayMode) {
    return makeSpan(["katex-display"], [katexNode]);
  } else {
    return katexNode;
  }
};
module.exports = buildTree;