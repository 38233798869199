/**
 * This file contains a list of utility functions which are useful in other
 * files.
 */

/**
 * Provide an `indexOf` function which works in IE8, but defers to native if
 * possible.
 */
var nativeIndexOf = Array.prototype.indexOf;
var indexOf = function (list, elem) {
  if (list == null) {
    return -1;
  }
  if (nativeIndexOf && list.indexOf === nativeIndexOf) {
    return list.indexOf(elem);
  }
  var i = 0;
  var l = list.length;
  for (; i < l; i++) {
    if (list[i] === elem) {
      return i;
    }
  }
  return -1;
};

/**
 * Return whether an element is contained in a list
 */
var contains = function (list, elem) {
  return indexOf(list, elem) !== -1;
};

/**
 * Provide a default value if a setting is undefined
 */
var deflt = function (setting, defaultIfUndefined) {
  return setting === undefined ? defaultIfUndefined : setting;
};

// hyphenate and escape adapted from Facebook's React under Apache 2 license

var uppercase = /([A-Z])/g;
var hyphenate = function (str) {
  return str.replace(uppercase, "-$1").toLowerCase();
};
var ESCAPE_LOOKUP = {
  "&": "&amp;",
  ">": "&gt;",
  "<": "&lt;",
  "\"": "&quot;",
  "'": "&#x27;"
};
var ESCAPE_REGEX = /[&><"']/g;
function escaper(match) {
  return ESCAPE_LOOKUP[match];
}

/**
 * Escapes text to prevent scripting attacks.
 *
 * @param {*} text Text value to escape.
 * @return {string} An escaped string.
 */
function escape(text) {
  return ("" + text).replace(ESCAPE_REGEX, escaper);
}

/**
 * A function to set the text content of a DOM element in all supported
 * browsers. Note that we don't define this if there is no document.
 */
var setTextContent;
if (typeof document !== "undefined") {
  var testNode = document.createElement("span");
  if ("textContent" in testNode) {
    setTextContent = function (node, text) {
      node.textContent = text;
    };
  } else {
    setTextContent = function (node, text) {
      node.innerText = text;
    };
  }
}

/**
 * A function to clear a node.
 */
function clearNode(node) {
  setTextContent(node, "");
}
module.exports = {
  contains: contains,
  deflt: deflt,
  escape: escape,
  hyphenate: hyphenate,
  indexOf: indexOf,
  setTextContent: setTextContent,
  clearNode: clearNode
};