/**
 * These objects store data about MathML nodes. This is the MathML equivalent
 * of the types in domTree.js. Since MathML handles its own rendering, and
 * since we're mainly using MathML to improve accessibility, we don't manage
 * any of the styling state that the plain DOM nodes do.
 *
 * The `toNode` and `toMarkup` functions work simlarly to how they do in
 * domTree.js, creating namespaced DOM nodes and HTML text markup respectively.
 */

var utils = require("./utils");

/**
 * This node represents a general purpose MathML node of any type. The
 * constructor requires the type of node to create (for example, `"mo"` or
 * `"mspace"`, corresponding to `<mo>` and `<mspace>` tags).
 */
function MathNode(type, children) {
  this.type = type;
  this.attributes = {};
  this.children = children || [];
}

/**
 * Sets an attribute on a MathML node. MathML depends on attributes to convey a
 * semantic content, so this is used heavily.
 */
MathNode.prototype.setAttribute = function (name, value) {
  this.attributes[name] = value;
};

/**
 * Converts the math node into a MathML-namespaced DOM element.
 */
MathNode.prototype.toNode = function () {
  var node = document.createElementNS("http://www.w3.org/1998/Math/MathML", this.type);
  for (var attr in this.attributes) {
    if (Object.prototype.hasOwnProperty.call(this.attributes, attr)) {
      node.setAttribute(attr, this.attributes[attr]);
    }
  }
  for (var i = 0; i < this.children.length; i++) {
    node.appendChild(this.children[i].toNode());
  }
  return node;
};

/**
 * Converts the math node into an HTML markup string.
 */
MathNode.prototype.toMarkup = function () {
  var markup = "<" + this.type;

  // Add the attributes
  for (var attr in this.attributes) {
    if (Object.prototype.hasOwnProperty.call(this.attributes, attr)) {
      markup += " " + attr + "=\"";
      markup += utils.escape(this.attributes[attr]);
      markup += "\"";
    }
  }
  markup += ">";
  for (var i = 0; i < this.children.length; i++) {
    markup += this.children[i].toMarkup();
  }
  markup += "</" + this.type + ">";
  return markup;
};

/**
 * This node represents a piece of text.
 */
function TextNode(text) {
  this.text = text;
}

/**
 * Converts the text node into a DOM text node.
 */
TextNode.prototype.toNode = function () {
  return document.createTextNode(this.text);
};

/**
 * Converts the text node into HTML markup (which is just the text itself).
 */
TextNode.prototype.toMarkup = function () {
  return utils.escape(this.text);
};
module.exports = {
  MathNode: MathNode,
  TextNode: TextNode
};