module.exports = {
  "AMS-Regular": {
    "65": [0, 0.68889, 0, 0],
    "66": [0, 0.68889, 0, 0],
    "67": [0, 0.68889, 0, 0],
    "68": [0, 0.68889, 0, 0],
    "69": [0, 0.68889, 0, 0],
    "70": [0, 0.68889, 0, 0],
    "71": [0, 0.68889, 0, 0],
    "72": [0, 0.68889, 0, 0],
    "73": [0, 0.68889, 0, 0],
    "74": [0.16667, 0.68889, 0, 0],
    "75": [0, 0.68889, 0, 0],
    "76": [0, 0.68889, 0, 0],
    "77": [0, 0.68889, 0, 0],
    "78": [0, 0.68889, 0, 0],
    "79": [0.16667, 0.68889, 0, 0],
    "80": [0, 0.68889, 0, 0],
    "81": [0.16667, 0.68889, 0, 0],
    "82": [0, 0.68889, 0, 0],
    "83": [0, 0.68889, 0, 0],
    "84": [0, 0.68889, 0, 0],
    "85": [0, 0.68889, 0, 0],
    "86": [0, 0.68889, 0, 0],
    "87": [0, 0.68889, 0, 0],
    "88": [0, 0.68889, 0, 0],
    "89": [0, 0.68889, 0, 0],
    "90": [0, 0.68889, 0, 0],
    "107": [0, 0.68889, 0, 0],
    "165": [0, 0.675, 0.025, 0],
    "174": [0.15559, 0.69224, 0, 0],
    "240": [0, 0.68889, 0, 0],
    "295": [0, 0.68889, 0, 0],
    "710": [0, 0.825, 0, 0],
    "732": [0, 0.9, 0, 0],
    "770": [0, 0.825, 0, 0],
    "771": [0, 0.9, 0, 0],
    "989": [0.08167, 0.58167, 0, 0],
    "1008": [0, 0.43056, 0.04028, 0],
    "8245": [0, 0.54986, 0, 0],
    "8463": [0, 0.68889, 0, 0],
    "8487": [0, 0.68889, 0, 0],
    "8498": [0, 0.68889, 0, 0],
    "8502": [0, 0.68889, 0, 0],
    "8503": [0, 0.68889, 0, 0],
    "8504": [0, 0.68889, 0, 0],
    "8513": [0, 0.68889, 0, 0],
    "8592": [-0.03598, 0.46402, 0, 0],
    "8594": [-0.03598, 0.46402, 0, 0],
    "8602": [-0.13313, 0.36687, 0, 0],
    "8603": [-0.13313, 0.36687, 0, 0],
    "8606": [0.01354, 0.52239, 0, 0],
    "8608": [0.01354, 0.52239, 0, 0],
    "8610": [0.01354, 0.52239, 0, 0],
    "8611": [0.01354, 0.52239, 0, 0],
    "8619": [0, 0.54986, 0, 0],
    "8620": [0, 0.54986, 0, 0],
    "8621": [-0.13313, 0.37788, 0, 0],
    "8622": [-0.13313, 0.36687, 0, 0],
    "8624": [0, 0.69224, 0, 0],
    "8625": [0, 0.69224, 0, 0],
    "8630": [0, 0.43056, 0, 0],
    "8631": [0, 0.43056, 0, 0],
    "8634": [0.08198, 0.58198, 0, 0],
    "8635": [0.08198, 0.58198, 0, 0],
    "8638": [0.19444, 0.69224, 0, 0],
    "8639": [0.19444, 0.69224, 0, 0],
    "8642": [0.19444, 0.69224, 0, 0],
    "8643": [0.19444, 0.69224, 0, 0],
    "8644": [0.1808, 0.675, 0, 0],
    "8646": [0.1808, 0.675, 0, 0],
    "8647": [0.1808, 0.675, 0, 0],
    "8648": [0.19444, 0.69224, 0, 0],
    "8649": [0.1808, 0.675, 0, 0],
    "8650": [0.19444, 0.69224, 0, 0],
    "8651": [0.01354, 0.52239, 0, 0],
    "8652": [0.01354, 0.52239, 0, 0],
    "8653": [-0.13313, 0.36687, 0, 0],
    "8654": [-0.13313, 0.36687, 0, 0],
    "8655": [-0.13313, 0.36687, 0, 0],
    "8666": [0.13667, 0.63667, 0, 0],
    "8667": [0.13667, 0.63667, 0, 0],
    "8669": [-0.13313, 0.37788, 0, 0],
    "8672": [-0.064, 0.437, 0, 0],
    "8674": [-0.064, 0.437, 0, 0],
    "8705": [0, 0.825, 0, 0],
    "8708": [0, 0.68889, 0, 0],
    "8709": [0.08167, 0.58167, 0, 0],
    "8717": [0, 0.43056, 0, 0],
    "8722": [-0.03598, 0.46402, 0, 0],
    "8724": [0.08198, 0.69224, 0, 0],
    "8726": [0.08167, 0.58167, 0, 0],
    "8733": [0, 0.69224, 0, 0],
    "8736": [0, 0.69224, 0, 0],
    "8737": [0, 0.69224, 0, 0],
    "8738": [0.03517, 0.52239, 0, 0],
    "8739": [0.08167, 0.58167, 0, 0],
    "8740": [0.25142, 0.74111, 0, 0],
    "8741": [0.08167, 0.58167, 0, 0],
    "8742": [0.25142, 0.74111, 0, 0],
    "8756": [0, 0.69224, 0, 0],
    "8757": [0, 0.69224, 0, 0],
    "8764": [-0.13313, 0.36687, 0, 0],
    "8765": [-0.13313, 0.37788, 0, 0],
    "8769": [-0.13313, 0.36687, 0, 0],
    "8770": [-0.03625, 0.46375, 0, 0],
    "8774": [0.30274, 0.79383, 0, 0],
    "8776": [-0.01688, 0.48312, 0, 0],
    "8778": [0.08167, 0.58167, 0, 0],
    "8782": [0.06062, 0.54986, 0, 0],
    "8783": [0.06062, 0.54986, 0, 0],
    "8785": [0.08198, 0.58198, 0, 0],
    "8786": [0.08198, 0.58198, 0, 0],
    "8787": [0.08198, 0.58198, 0, 0],
    "8790": [0, 0.69224, 0, 0],
    "8791": [0.22958, 0.72958, 0, 0],
    "8796": [0.08198, 0.91667, 0, 0],
    "8806": [0.25583, 0.75583, 0, 0],
    "8807": [0.25583, 0.75583, 0, 0],
    "8808": [0.25142, 0.75726, 0, 0],
    "8809": [0.25142, 0.75726, 0, 0],
    "8812": [0.25583, 0.75583, 0, 0],
    "8814": [0.20576, 0.70576, 0, 0],
    "8815": [0.20576, 0.70576, 0, 0],
    "8816": [0.30274, 0.79383, 0, 0],
    "8817": [0.30274, 0.79383, 0, 0],
    "8818": [0.22958, 0.72958, 0, 0],
    "8819": [0.22958, 0.72958, 0, 0],
    "8822": [0.1808, 0.675, 0, 0],
    "8823": [0.1808, 0.675, 0, 0],
    "8828": [0.13667, 0.63667, 0, 0],
    "8829": [0.13667, 0.63667, 0, 0],
    "8830": [0.22958, 0.72958, 0, 0],
    "8831": [0.22958, 0.72958, 0, 0],
    "8832": [0.20576, 0.70576, 0, 0],
    "8833": [0.20576, 0.70576, 0, 0],
    "8840": [0.30274, 0.79383, 0, 0],
    "8841": [0.30274, 0.79383, 0, 0],
    "8842": [0.13597, 0.63597, 0, 0],
    "8843": [0.13597, 0.63597, 0, 0],
    "8847": [0.03517, 0.54986, 0, 0],
    "8848": [0.03517, 0.54986, 0, 0],
    "8858": [0.08198, 0.58198, 0, 0],
    "8859": [0.08198, 0.58198, 0, 0],
    "8861": [0.08198, 0.58198, 0, 0],
    "8862": [0, 0.675, 0, 0],
    "8863": [0, 0.675, 0, 0],
    "8864": [0, 0.675, 0, 0],
    "8865": [0, 0.675, 0, 0],
    "8872": [0, 0.69224, 0, 0],
    "8873": [0, 0.69224, 0, 0],
    "8874": [0, 0.69224, 0, 0],
    "8876": [0, 0.68889, 0, 0],
    "8877": [0, 0.68889, 0, 0],
    "8878": [0, 0.68889, 0, 0],
    "8879": [0, 0.68889, 0, 0],
    "8882": [0.03517, 0.54986, 0, 0],
    "8883": [0.03517, 0.54986, 0, 0],
    "8884": [0.13667, 0.63667, 0, 0],
    "8885": [0.13667, 0.63667, 0, 0],
    "8888": [0, 0.54986, 0, 0],
    "8890": [0.19444, 0.43056, 0, 0],
    "8891": [0.19444, 0.69224, 0, 0],
    "8892": [0.19444, 0.69224, 0, 0],
    "8901": [0, 0.54986, 0, 0],
    "8903": [0.08167, 0.58167, 0, 0],
    "8905": [0.08167, 0.58167, 0, 0],
    "8906": [0.08167, 0.58167, 0, 0],
    "8907": [0, 0.69224, 0, 0],
    "8908": [0, 0.69224, 0, 0],
    "8909": [-0.03598, 0.46402, 0, 0],
    "8910": [0, 0.54986, 0, 0],
    "8911": [0, 0.54986, 0, 0],
    "8912": [0.03517, 0.54986, 0, 0],
    "8913": [0.03517, 0.54986, 0, 0],
    "8914": [0, 0.54986, 0, 0],
    "8915": [0, 0.54986, 0, 0],
    "8916": [0, 0.69224, 0, 0],
    "8918": [0.0391, 0.5391, 0, 0],
    "8919": [0.0391, 0.5391, 0, 0],
    "8920": [0.03517, 0.54986, 0, 0],
    "8921": [0.03517, 0.54986, 0, 0],
    "8922": [0.38569, 0.88569, 0, 0],
    "8923": [0.38569, 0.88569, 0, 0],
    "8926": [0.13667, 0.63667, 0, 0],
    "8927": [0.13667, 0.63667, 0, 0],
    "8928": [0.30274, 0.79383, 0, 0],
    "8929": [0.30274, 0.79383, 0, 0],
    "8934": [0.23222, 0.74111, 0, 0],
    "8935": [0.23222, 0.74111, 0, 0],
    "8936": [0.23222, 0.74111, 0, 0],
    "8937": [0.23222, 0.74111, 0, 0],
    "8938": [0.20576, 0.70576, 0, 0],
    "8939": [0.20576, 0.70576, 0, 0],
    "8940": [0.30274, 0.79383, 0, 0],
    "8941": [0.30274, 0.79383, 0, 0],
    "8994": [0.19444, 0.69224, 0, 0],
    "8995": [0.19444, 0.69224, 0, 0],
    "9416": [0.15559, 0.69224, 0, 0],
    "9484": [0, 0.69224, 0, 0],
    "9488": [0, 0.69224, 0, 0],
    "9492": [0, 0.37788, 0, 0],
    "9496": [0, 0.37788, 0, 0],
    "9585": [0.19444, 0.68889, 0, 0],
    "9586": [0.19444, 0.74111, 0, 0],
    "9632": [0, 0.675, 0, 0],
    "9633": [0, 0.675, 0, 0],
    "9650": [0, 0.54986, 0, 0],
    "9651": [0, 0.54986, 0, 0],
    "9654": [0.03517, 0.54986, 0, 0],
    "9660": [0, 0.54986, 0, 0],
    "9661": [0, 0.54986, 0, 0],
    "9664": [0.03517, 0.54986, 0, 0],
    "9674": [0.11111, 0.69224, 0, 0],
    "9733": [0.19444, 0.69224, 0, 0],
    "10003": [0, 0.69224, 0, 0],
    "10016": [0, 0.69224, 0, 0],
    "10731": [0.11111, 0.69224, 0, 0],
    "10846": [0.19444, 0.75583, 0, 0],
    "10877": [0.13667, 0.63667, 0, 0],
    "10878": [0.13667, 0.63667, 0, 0],
    "10885": [0.25583, 0.75583, 0, 0],
    "10886": [0.25583, 0.75583, 0, 0],
    "10887": [0.13597, 0.63597, 0, 0],
    "10888": [0.13597, 0.63597, 0, 0],
    "10889": [0.26167, 0.75726, 0, 0],
    "10890": [0.26167, 0.75726, 0, 0],
    "10891": [0.48256, 0.98256, 0, 0],
    "10892": [0.48256, 0.98256, 0, 0],
    "10901": [0.13667, 0.63667, 0, 0],
    "10902": [0.13667, 0.63667, 0, 0],
    "10933": [0.25142, 0.75726, 0, 0],
    "10934": [0.25142, 0.75726, 0, 0],
    "10935": [0.26167, 0.75726, 0, 0],
    "10936": [0.26167, 0.75726, 0, 0],
    "10937": [0.26167, 0.75726, 0, 0],
    "10938": [0.26167, 0.75726, 0, 0],
    "10949": [0.25583, 0.75583, 0, 0],
    "10950": [0.25583, 0.75583, 0, 0],
    "10955": [0.28481, 0.79383, 0, 0],
    "10956": [0.28481, 0.79383, 0, 0],
    "57350": [0.08167, 0.58167, 0, 0],
    "57351": [0.08167, 0.58167, 0, 0],
    "57352": [0.08167, 0.58167, 0, 0],
    "57353": [0, 0.43056, 0.04028, 0],
    "57356": [0.25142, 0.75726, 0, 0],
    "57357": [0.25142, 0.75726, 0, 0],
    "57358": [0.41951, 0.91951, 0, 0],
    "57359": [0.30274, 0.79383, 0, 0],
    "57360": [0.30274, 0.79383, 0, 0],
    "57361": [0.41951, 0.91951, 0, 0],
    "57366": [0.25142, 0.75726, 0, 0],
    "57367": [0.25142, 0.75726, 0, 0],
    "57368": [0.25142, 0.75726, 0, 0],
    "57369": [0.25142, 0.75726, 0, 0],
    "57370": [0.13597, 0.63597, 0, 0],
    "57371": [0.13597, 0.63597, 0, 0]
  },
  "Caligraphic-Regular": {
    "48": [0, 0.43056, 0, 0],
    "49": [0, 0.43056, 0, 0],
    "50": [0, 0.43056, 0, 0],
    "51": [0.19444, 0.43056, 0, 0],
    "52": [0.19444, 0.43056, 0, 0],
    "53": [0.19444, 0.43056, 0, 0],
    "54": [0, 0.64444, 0, 0],
    "55": [0.19444, 0.43056, 0, 0],
    "56": [0, 0.64444, 0, 0],
    "57": [0.19444, 0.43056, 0, 0],
    "65": [0, 0.68333, 0, 0.19445],
    "66": [0, 0.68333, 0.03041, 0.13889],
    "67": [0, 0.68333, 0.05834, 0.13889],
    "68": [0, 0.68333, 0.02778, 0.08334],
    "69": [0, 0.68333, 0.08944, 0.11111],
    "70": [0, 0.68333, 0.09931, 0.11111],
    "71": [0.09722, 0.68333, 0.0593, 0.11111],
    "72": [0, 0.68333, 0.00965, 0.11111],
    "73": [0, 0.68333, 0.07382, 0],
    "74": [0.09722, 0.68333, 0.18472, 0.16667],
    "75": [0, 0.68333, 0.01445, 0.05556],
    "76": [0, 0.68333, 0, 0.13889],
    "77": [0, 0.68333, 0, 0.13889],
    "78": [0, 0.68333, 0.14736, 0.08334],
    "79": [0, 0.68333, 0.02778, 0.11111],
    "80": [0, 0.68333, 0.08222, 0.08334],
    "81": [0.09722, 0.68333, 0, 0.11111],
    "82": [0, 0.68333, 0, 0.08334],
    "83": [0, 0.68333, 0.075, 0.13889],
    "84": [0, 0.68333, 0.25417, 0],
    "85": [0, 0.68333, 0.09931, 0.08334],
    "86": [0, 0.68333, 0.08222, 0],
    "87": [0, 0.68333, 0.08222, 0.08334],
    "88": [0, 0.68333, 0.14643, 0.13889],
    "89": [0.09722, 0.68333, 0.08222, 0.08334],
    "90": [0, 0.68333, 0.07944, 0.13889]
  },
  "Fraktur-Regular": {
    "33": [0, 0.69141, 0, 0],
    "34": [0, 0.69141, 0, 0],
    "38": [0, 0.69141, 0, 0],
    "39": [0, 0.69141, 0, 0],
    "40": [0.24982, 0.74947, 0, 0],
    "41": [0.24982, 0.74947, 0, 0],
    "42": [0, 0.62119, 0, 0],
    "43": [0.08319, 0.58283, 0, 0],
    "44": [0, 0.10803, 0, 0],
    "45": [0.08319, 0.58283, 0, 0],
    "46": [0, 0.10803, 0, 0],
    "47": [0.24982, 0.74947, 0, 0],
    "48": [0, 0.47534, 0, 0],
    "49": [0, 0.47534, 0, 0],
    "50": [0, 0.47534, 0, 0],
    "51": [0.18906, 0.47534, 0, 0],
    "52": [0.18906, 0.47534, 0, 0],
    "53": [0.18906, 0.47534, 0, 0],
    "54": [0, 0.69141, 0, 0],
    "55": [0.18906, 0.47534, 0, 0],
    "56": [0, 0.69141, 0, 0],
    "57": [0.18906, 0.47534, 0, 0],
    "58": [0, 0.47534, 0, 0],
    "59": [0.12604, 0.47534, 0, 0],
    "61": [-0.13099, 0.36866, 0, 0],
    "63": [0, 0.69141, 0, 0],
    "65": [0, 0.69141, 0, 0],
    "66": [0, 0.69141, 0, 0],
    "67": [0, 0.69141, 0, 0],
    "68": [0, 0.69141, 0, 0],
    "69": [0, 0.69141, 0, 0],
    "70": [0.12604, 0.69141, 0, 0],
    "71": [0, 0.69141, 0, 0],
    "72": [0.06302, 0.69141, 0, 0],
    "73": [0, 0.69141, 0, 0],
    "74": [0.12604, 0.69141, 0, 0],
    "75": [0, 0.69141, 0, 0],
    "76": [0, 0.69141, 0, 0],
    "77": [0, 0.69141, 0, 0],
    "78": [0, 0.69141, 0, 0],
    "79": [0, 0.69141, 0, 0],
    "80": [0.18906, 0.69141, 0, 0],
    "81": [0.03781, 0.69141, 0, 0],
    "82": [0, 0.69141, 0, 0],
    "83": [0, 0.69141, 0, 0],
    "84": [0, 0.69141, 0, 0],
    "85": [0, 0.69141, 0, 0],
    "86": [0, 0.69141, 0, 0],
    "87": [0, 0.69141, 0, 0],
    "88": [0, 0.69141, 0, 0],
    "89": [0.18906, 0.69141, 0, 0],
    "90": [0.12604, 0.69141, 0, 0],
    "91": [0.24982, 0.74947, 0, 0],
    "93": [0.24982, 0.74947, 0, 0],
    "94": [0, 0.69141, 0, 0],
    "97": [0, 0.47534, 0, 0],
    "98": [0, 0.69141, 0, 0],
    "99": [0, 0.47534, 0, 0],
    "100": [0, 0.62119, 0, 0],
    "101": [0, 0.47534, 0, 0],
    "102": [0.18906, 0.69141, 0, 0],
    "103": [0.18906, 0.47534, 0, 0],
    "104": [0.18906, 0.69141, 0, 0],
    "105": [0, 0.69141, 0, 0],
    "106": [0, 0.69141, 0, 0],
    "107": [0, 0.69141, 0, 0],
    "108": [0, 0.69141, 0, 0],
    "109": [0, 0.47534, 0, 0],
    "110": [0, 0.47534, 0, 0],
    "111": [0, 0.47534, 0, 0],
    "112": [0.18906, 0.52396, 0, 0],
    "113": [0.18906, 0.47534, 0, 0],
    "114": [0, 0.47534, 0, 0],
    "115": [0, 0.47534, 0, 0],
    "116": [0, 0.62119, 0, 0],
    "117": [0, 0.47534, 0, 0],
    "118": [0, 0.52396, 0, 0],
    "119": [0, 0.52396, 0, 0],
    "120": [0.18906, 0.47534, 0, 0],
    "121": [0.18906, 0.47534, 0, 0],
    "122": [0.18906, 0.47534, 0, 0],
    "8216": [0, 0.69141, 0, 0],
    "8217": [0, 0.69141, 0, 0],
    "58112": [0, 0.62119, 0, 0],
    "58113": [0, 0.62119, 0, 0],
    "58114": [0.18906, 0.69141, 0, 0],
    "58115": [0.18906, 0.69141, 0, 0],
    "58116": [0.18906, 0.47534, 0, 0],
    "58117": [0, 0.69141, 0, 0],
    "58118": [0, 0.62119, 0, 0],
    "58119": [0, 0.47534, 0, 0]
  },
  "Main-Bold": {
    "33": [0, 0.69444, 0, 0],
    "34": [0, 0.69444, 0, 0],
    "35": [0.19444, 0.69444, 0, 0],
    "36": [0.05556, 0.75, 0, 0],
    "37": [0.05556, 0.75, 0, 0],
    "38": [0, 0.69444, 0, 0],
    "39": [0, 0.69444, 0, 0],
    "40": [0.25, 0.75, 0, 0],
    "41": [0.25, 0.75, 0, 0],
    "42": [0, 0.75, 0, 0],
    "43": [0.13333, 0.63333, 0, 0],
    "44": [0.19444, 0.15556, 0, 0],
    "45": [0, 0.44444, 0, 0],
    "46": [0, 0.15556, 0, 0],
    "47": [0.25, 0.75, 0, 0],
    "48": [0, 0.64444, 0, 0],
    "49": [0, 0.64444, 0, 0],
    "50": [0, 0.64444, 0, 0],
    "51": [0, 0.64444, 0, 0],
    "52": [0, 0.64444, 0, 0],
    "53": [0, 0.64444, 0, 0],
    "54": [0, 0.64444, 0, 0],
    "55": [0, 0.64444, 0, 0],
    "56": [0, 0.64444, 0, 0],
    "57": [0, 0.64444, 0, 0],
    "58": [0, 0.44444, 0, 0],
    "59": [0.19444, 0.44444, 0, 0],
    "60": [0.08556, 0.58556, 0, 0],
    "61": [-0.10889, 0.39111, 0, 0],
    "62": [0.08556, 0.58556, 0, 0],
    "63": [0, 0.69444, 0, 0],
    "64": [0, 0.69444, 0, 0],
    "65": [0, 0.68611, 0, 0],
    "66": [0, 0.68611, 0, 0],
    "67": [0, 0.68611, 0, 0],
    "68": [0, 0.68611, 0, 0],
    "69": [0, 0.68611, 0, 0],
    "70": [0, 0.68611, 0, 0],
    "71": [0, 0.68611, 0, 0],
    "72": [0, 0.68611, 0, 0],
    "73": [0, 0.68611, 0, 0],
    "74": [0, 0.68611, 0, 0],
    "75": [0, 0.68611, 0, 0],
    "76": [0, 0.68611, 0, 0],
    "77": [0, 0.68611, 0, 0],
    "78": [0, 0.68611, 0, 0],
    "79": [0, 0.68611, 0, 0],
    "80": [0, 0.68611, 0, 0],
    "81": [0.19444, 0.68611, 0, 0],
    "82": [0, 0.68611, 0, 0],
    "83": [0, 0.68611, 0, 0],
    "84": [0, 0.68611, 0, 0],
    "85": [0, 0.68611, 0, 0],
    "86": [0, 0.68611, 0.01597, 0],
    "87": [0, 0.68611, 0.01597, 0],
    "88": [0, 0.68611, 0, 0],
    "89": [0, 0.68611, 0.02875, 0],
    "90": [0, 0.68611, 0, 0],
    "91": [0.25, 0.75, 0, 0],
    "92": [0.25, 0.75, 0, 0],
    "93": [0.25, 0.75, 0, 0],
    "94": [0, 0.69444, 0, 0],
    "95": [0.31, 0.13444, 0.03194, 0],
    "96": [0, 0.69444, 0, 0],
    "97": [0, 0.44444, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.44444, 0, 0],
    "100": [0, 0.69444, 0, 0],
    "101": [0, 0.44444, 0, 0],
    "102": [0, 0.69444, 0.10903, 0],
    "103": [0.19444, 0.44444, 0.01597, 0],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.69444, 0, 0],
    "106": [0.19444, 0.69444, 0, 0],
    "107": [0, 0.69444, 0, 0],
    "108": [0, 0.69444, 0, 0],
    "109": [0, 0.44444, 0, 0],
    "110": [0, 0.44444, 0, 0],
    "111": [0, 0.44444, 0, 0],
    "112": [0.19444, 0.44444, 0, 0],
    "113": [0.19444, 0.44444, 0, 0],
    "114": [0, 0.44444, 0, 0],
    "115": [0, 0.44444, 0, 0],
    "116": [0, 0.63492, 0, 0],
    "117": [0, 0.44444, 0, 0],
    "118": [0, 0.44444, 0.01597, 0],
    "119": [0, 0.44444, 0.01597, 0],
    "120": [0, 0.44444, 0, 0],
    "121": [0.19444, 0.44444, 0.01597, 0],
    "122": [0, 0.44444, 0, 0],
    "123": [0.25, 0.75, 0, 0],
    "124": [0.25, 0.75, 0, 0],
    "125": [0.25, 0.75, 0, 0],
    "126": [0.35, 0.34444, 0, 0],
    "168": [0, 0.69444, 0, 0],
    "172": [0, 0.44444, 0, 0],
    "175": [0, 0.59611, 0, 0],
    "176": [0, 0.69444, 0, 0],
    "177": [0.13333, 0.63333, 0, 0],
    "180": [0, 0.69444, 0, 0],
    "215": [0.13333, 0.63333, 0, 0],
    "247": [0.13333, 0.63333, 0, 0],
    "305": [0, 0.44444, 0, 0],
    "567": [0.19444, 0.44444, 0, 0],
    "710": [0, 0.69444, 0, 0],
    "711": [0, 0.63194, 0, 0],
    "713": [0, 0.59611, 0, 0],
    "714": [0, 0.69444, 0, 0],
    "715": [0, 0.69444, 0, 0],
    "728": [0, 0.69444, 0, 0],
    "729": [0, 0.69444, 0, 0],
    "730": [0, 0.69444, 0, 0],
    "732": [0, 0.69444, 0, 0],
    "768": [0, 0.69444, 0, 0],
    "769": [0, 0.69444, 0, 0],
    "770": [0, 0.69444, 0, 0],
    "771": [0, 0.69444, 0, 0],
    "772": [0, 0.59611, 0, 0],
    "774": [0, 0.69444, 0, 0],
    "775": [0, 0.69444, 0, 0],
    "776": [0, 0.69444, 0, 0],
    "778": [0, 0.69444, 0, 0],
    "779": [0, 0.69444, 0, 0],
    "780": [0, 0.63194, 0, 0],
    "824": [0.19444, 0.69444, 0, 0],
    "915": [0, 0.68611, 0, 0],
    "916": [0, 0.68611, 0, 0],
    "920": [0, 0.68611, 0, 0],
    "923": [0, 0.68611, 0, 0],
    "926": [0, 0.68611, 0, 0],
    "928": [0, 0.68611, 0, 0],
    "931": [0, 0.68611, 0, 0],
    "933": [0, 0.68611, 0, 0],
    "934": [0, 0.68611, 0, 0],
    "936": [0, 0.68611, 0, 0],
    "937": [0, 0.68611, 0, 0],
    "8211": [0, 0.44444, 0.03194, 0],
    "8212": [0, 0.44444, 0.03194, 0],
    "8216": [0, 0.69444, 0, 0],
    "8217": [0, 0.69444, 0, 0],
    "8220": [0, 0.69444, 0, 0],
    "8221": [0, 0.69444, 0, 0],
    "8224": [0.19444, 0.69444, 0, 0],
    "8225": [0.19444, 0.69444, 0, 0],
    "8242": [0, 0.55556, 0, 0],
    "8407": [0, 0.72444, 0.15486, 0],
    "8463": [0, 0.69444, 0, 0],
    "8465": [0, 0.69444, 0, 0],
    "8467": [0, 0.69444, 0, 0],
    "8472": [0.19444, 0.44444, 0, 0],
    "8476": [0, 0.69444, 0, 0],
    "8501": [0, 0.69444, 0, 0],
    "8592": [-0.10889, 0.39111, 0, 0],
    "8593": [0.19444, 0.69444, 0, 0],
    "8594": [-0.10889, 0.39111, 0, 0],
    "8595": [0.19444, 0.69444, 0, 0],
    "8596": [-0.10889, 0.39111, 0, 0],
    "8597": [0.25, 0.75, 0, 0],
    "8598": [0.19444, 0.69444, 0, 0],
    "8599": [0.19444, 0.69444, 0, 0],
    "8600": [0.19444, 0.69444, 0, 0],
    "8601": [0.19444, 0.69444, 0, 0],
    "8636": [-0.10889, 0.39111, 0, 0],
    "8637": [-0.10889, 0.39111, 0, 0],
    "8640": [-0.10889, 0.39111, 0, 0],
    "8641": [-0.10889, 0.39111, 0, 0],
    "8656": [-0.10889, 0.39111, 0, 0],
    "8657": [0.19444, 0.69444, 0, 0],
    "8658": [-0.10889, 0.39111, 0, 0],
    "8659": [0.19444, 0.69444, 0, 0],
    "8660": [-0.10889, 0.39111, 0, 0],
    "8661": [0.25, 0.75, 0, 0],
    "8704": [0, 0.69444, 0, 0],
    "8706": [0, 0.69444, 0.06389, 0],
    "8707": [0, 0.69444, 0, 0],
    "8709": [0.05556, 0.75, 0, 0],
    "8711": [0, 0.68611, 0, 0],
    "8712": [0.08556, 0.58556, 0, 0],
    "8715": [0.08556, 0.58556, 0, 0],
    "8722": [0.13333, 0.63333, 0, 0],
    "8723": [0.13333, 0.63333, 0, 0],
    "8725": [0.25, 0.75, 0, 0],
    "8726": [0.25, 0.75, 0, 0],
    "8727": [-0.02778, 0.47222, 0, 0],
    "8728": [-0.02639, 0.47361, 0, 0],
    "8729": [-0.02639, 0.47361, 0, 0],
    "8730": [0.18, 0.82, 0, 0],
    "8733": [0, 0.44444, 0, 0],
    "8734": [0, 0.44444, 0, 0],
    "8736": [0, 0.69224, 0, 0],
    "8739": [0.25, 0.75, 0, 0],
    "8741": [0.25, 0.75, 0, 0],
    "8743": [0, 0.55556, 0, 0],
    "8744": [0, 0.55556, 0, 0],
    "8745": [0, 0.55556, 0, 0],
    "8746": [0, 0.55556, 0, 0],
    "8747": [0.19444, 0.69444, 0.12778, 0],
    "8764": [-0.10889, 0.39111, 0, 0],
    "8768": [0.19444, 0.69444, 0, 0],
    "8771": [0.00222, 0.50222, 0, 0],
    "8776": [0.02444, 0.52444, 0, 0],
    "8781": [0.00222, 0.50222, 0, 0],
    "8801": [0.00222, 0.50222, 0, 0],
    "8804": [0.19667, 0.69667, 0, 0],
    "8805": [0.19667, 0.69667, 0, 0],
    "8810": [0.08556, 0.58556, 0, 0],
    "8811": [0.08556, 0.58556, 0, 0],
    "8826": [0.08556, 0.58556, 0, 0],
    "8827": [0.08556, 0.58556, 0, 0],
    "8834": [0.08556, 0.58556, 0, 0],
    "8835": [0.08556, 0.58556, 0, 0],
    "8838": [0.19667, 0.69667, 0, 0],
    "8839": [0.19667, 0.69667, 0, 0],
    "8846": [0, 0.55556, 0, 0],
    "8849": [0.19667, 0.69667, 0, 0],
    "8850": [0.19667, 0.69667, 0, 0],
    "8851": [0, 0.55556, 0, 0],
    "8852": [0, 0.55556, 0, 0],
    "8853": [0.13333, 0.63333, 0, 0],
    "8854": [0.13333, 0.63333, 0, 0],
    "8855": [0.13333, 0.63333, 0, 0],
    "8856": [0.13333, 0.63333, 0, 0],
    "8857": [0.13333, 0.63333, 0, 0],
    "8866": [0, 0.69444, 0, 0],
    "8867": [0, 0.69444, 0, 0],
    "8868": [0, 0.69444, 0, 0],
    "8869": [0, 0.69444, 0, 0],
    "8900": [-0.02639, 0.47361, 0, 0],
    "8901": [-0.02639, 0.47361, 0, 0],
    "8902": [-0.02778, 0.47222, 0, 0],
    "8968": [0.25, 0.75, 0, 0],
    "8969": [0.25, 0.75, 0, 0],
    "8970": [0.25, 0.75, 0, 0],
    "8971": [0.25, 0.75, 0, 0],
    "8994": [-0.13889, 0.36111, 0, 0],
    "8995": [-0.13889, 0.36111, 0, 0],
    "9651": [0.19444, 0.69444, 0, 0],
    "9657": [-0.02778, 0.47222, 0, 0],
    "9661": [0.19444, 0.69444, 0, 0],
    "9667": [-0.02778, 0.47222, 0, 0],
    "9711": [0.19444, 0.69444, 0, 0],
    "9824": [0.12963, 0.69444, 0, 0],
    "9825": [0.12963, 0.69444, 0, 0],
    "9826": [0.12963, 0.69444, 0, 0],
    "9827": [0.12963, 0.69444, 0, 0],
    "9837": [0, 0.75, 0, 0],
    "9838": [0.19444, 0.69444, 0, 0],
    "9839": [0.19444, 0.69444, 0, 0],
    "10216": [0.25, 0.75, 0, 0],
    "10217": [0.25, 0.75, 0, 0],
    "10815": [0, 0.68611, 0, 0],
    "10927": [0.19667, 0.69667, 0, 0],
    "10928": [0.19667, 0.69667, 0, 0]
  },
  "Main-Italic": {
    "33": [0, 0.69444, 0.12417, 0],
    "34": [0, 0.69444, 0.06961, 0],
    "35": [0.19444, 0.69444, 0.06616, 0],
    "37": [0.05556, 0.75, 0.13639, 0],
    "38": [0, 0.69444, 0.09694, 0],
    "39": [0, 0.69444, 0.12417, 0],
    "40": [0.25, 0.75, 0.16194, 0],
    "41": [0.25, 0.75, 0.03694, 0],
    "42": [0, 0.75, 0.14917, 0],
    "43": [0.05667, 0.56167, 0.03694, 0],
    "44": [0.19444, 0.10556, 0, 0],
    "45": [0, 0.43056, 0.02826, 0],
    "46": [0, 0.10556, 0, 0],
    "47": [0.25, 0.75, 0.16194, 0],
    "48": [0, 0.64444, 0.13556, 0],
    "49": [0, 0.64444, 0.13556, 0],
    "50": [0, 0.64444, 0.13556, 0],
    "51": [0, 0.64444, 0.13556, 0],
    "52": [0.19444, 0.64444, 0.13556, 0],
    "53": [0, 0.64444, 0.13556, 0],
    "54": [0, 0.64444, 0.13556, 0],
    "55": [0.19444, 0.64444, 0.13556, 0],
    "56": [0, 0.64444, 0.13556, 0],
    "57": [0, 0.64444, 0.13556, 0],
    "58": [0, 0.43056, 0.0582, 0],
    "59": [0.19444, 0.43056, 0.0582, 0],
    "61": [-0.13313, 0.36687, 0.06616, 0],
    "63": [0, 0.69444, 0.1225, 0],
    "64": [0, 0.69444, 0.09597, 0],
    "65": [0, 0.68333, 0, 0],
    "66": [0, 0.68333, 0.10257, 0],
    "67": [0, 0.68333, 0.14528, 0],
    "68": [0, 0.68333, 0.09403, 0],
    "69": [0, 0.68333, 0.12028, 0],
    "70": [0, 0.68333, 0.13305, 0],
    "71": [0, 0.68333, 0.08722, 0],
    "72": [0, 0.68333, 0.16389, 0],
    "73": [0, 0.68333, 0.15806, 0],
    "74": [0, 0.68333, 0.14028, 0],
    "75": [0, 0.68333, 0.14528, 0],
    "76": [0, 0.68333, 0, 0],
    "77": [0, 0.68333, 0.16389, 0],
    "78": [0, 0.68333, 0.16389, 0],
    "79": [0, 0.68333, 0.09403, 0],
    "80": [0, 0.68333, 0.10257, 0],
    "81": [0.19444, 0.68333, 0.09403, 0],
    "82": [0, 0.68333, 0.03868, 0],
    "83": [0, 0.68333, 0.11972, 0],
    "84": [0, 0.68333, 0.13305, 0],
    "85": [0, 0.68333, 0.16389, 0],
    "86": [0, 0.68333, 0.18361, 0],
    "87": [0, 0.68333, 0.18361, 0],
    "88": [0, 0.68333, 0.15806, 0],
    "89": [0, 0.68333, 0.19383, 0],
    "90": [0, 0.68333, 0.14528, 0],
    "91": [0.25, 0.75, 0.1875, 0],
    "93": [0.25, 0.75, 0.10528, 0],
    "94": [0, 0.69444, 0.06646, 0],
    "95": [0.31, 0.12056, 0.09208, 0],
    "97": [0, 0.43056, 0.07671, 0],
    "98": [0, 0.69444, 0.06312, 0],
    "99": [0, 0.43056, 0.05653, 0],
    "100": [0, 0.69444, 0.10333, 0],
    "101": [0, 0.43056, 0.07514, 0],
    "102": [0.19444, 0.69444, 0.21194, 0],
    "103": [0.19444, 0.43056, 0.08847, 0],
    "104": [0, 0.69444, 0.07671, 0],
    "105": [0, 0.65536, 0.1019, 0],
    "106": [0.19444, 0.65536, 0.14467, 0],
    "107": [0, 0.69444, 0.10764, 0],
    "108": [0, 0.69444, 0.10333, 0],
    "109": [0, 0.43056, 0.07671, 0],
    "110": [0, 0.43056, 0.07671, 0],
    "111": [0, 0.43056, 0.06312, 0],
    "112": [0.19444, 0.43056, 0.06312, 0],
    "113": [0.19444, 0.43056, 0.08847, 0],
    "114": [0, 0.43056, 0.10764, 0],
    "115": [0, 0.43056, 0.08208, 0],
    "116": [0, 0.61508, 0.09486, 0],
    "117": [0, 0.43056, 0.07671, 0],
    "118": [0, 0.43056, 0.10764, 0],
    "119": [0, 0.43056, 0.10764, 0],
    "120": [0, 0.43056, 0.12042, 0],
    "121": [0.19444, 0.43056, 0.08847, 0],
    "122": [0, 0.43056, 0.12292, 0],
    "126": [0.35, 0.31786, 0.11585, 0],
    "163": [0, 0.69444, 0, 0],
    "305": [0, 0.43056, 0, 0.02778],
    "567": [0.19444, 0.43056, 0, 0.08334],
    "768": [0, 0.69444, 0, 0],
    "769": [0, 0.69444, 0.09694, 0],
    "770": [0, 0.69444, 0.06646, 0],
    "771": [0, 0.66786, 0.11585, 0],
    "772": [0, 0.56167, 0.10333, 0],
    "774": [0, 0.69444, 0.10806, 0],
    "775": [0, 0.66786, 0.11752, 0],
    "776": [0, 0.66786, 0.10474, 0],
    "778": [0, 0.69444, 0, 0],
    "779": [0, 0.69444, 0.1225, 0],
    "780": [0, 0.62847, 0.08295, 0],
    "915": [0, 0.68333, 0.13305, 0],
    "916": [0, 0.68333, 0, 0],
    "920": [0, 0.68333, 0.09403, 0],
    "923": [0, 0.68333, 0, 0],
    "926": [0, 0.68333, 0.15294, 0],
    "928": [0, 0.68333, 0.16389, 0],
    "931": [0, 0.68333, 0.12028, 0],
    "933": [0, 0.68333, 0.11111, 0],
    "934": [0, 0.68333, 0.05986, 0],
    "936": [0, 0.68333, 0.11111, 0],
    "937": [0, 0.68333, 0.10257, 0],
    "8211": [0, 0.43056, 0.09208, 0],
    "8212": [0, 0.43056, 0.09208, 0],
    "8216": [0, 0.69444, 0.12417, 0],
    "8217": [0, 0.69444, 0.12417, 0],
    "8220": [0, 0.69444, 0.1685, 0],
    "8221": [0, 0.69444, 0.06961, 0],
    "8463": [0, 0.68889, 0, 0]
  },
  "Main-Regular": {
    "32": [0, 0, 0, 0],
    "33": [0, 0.69444, 0, 0],
    "34": [0, 0.69444, 0, 0],
    "35": [0.19444, 0.69444, 0, 0],
    "36": [0.05556, 0.75, 0, 0],
    "37": [0.05556, 0.75, 0, 0],
    "38": [0, 0.69444, 0, 0],
    "39": [0, 0.69444, 0, 0],
    "40": [0.25, 0.75, 0, 0],
    "41": [0.25, 0.75, 0, 0],
    "42": [0, 0.75, 0, 0],
    "43": [0.08333, 0.58333, 0, 0],
    "44": [0.19444, 0.10556, 0, 0],
    "45": [0, 0.43056, 0, 0],
    "46": [0, 0.10556, 0, 0],
    "47": [0.25, 0.75, 0, 0],
    "48": [0, 0.64444, 0, 0],
    "49": [0, 0.64444, 0, 0],
    "50": [0, 0.64444, 0, 0],
    "51": [0, 0.64444, 0, 0],
    "52": [0, 0.64444, 0, 0],
    "53": [0, 0.64444, 0, 0],
    "54": [0, 0.64444, 0, 0],
    "55": [0, 0.64444, 0, 0],
    "56": [0, 0.64444, 0, 0],
    "57": [0, 0.64444, 0, 0],
    "58": [0, 0.43056, 0, 0],
    "59": [0.19444, 0.43056, 0, 0],
    "60": [0.0391, 0.5391, 0, 0],
    "61": [-0.13313, 0.36687, 0, 0],
    "62": [0.0391, 0.5391, 0, 0],
    "63": [0, 0.69444, 0, 0],
    "64": [0, 0.69444, 0, 0],
    "65": [0, 0.68333, 0, 0],
    "66": [0, 0.68333, 0, 0],
    "67": [0, 0.68333, 0, 0],
    "68": [0, 0.68333, 0, 0],
    "69": [0, 0.68333, 0, 0],
    "70": [0, 0.68333, 0, 0],
    "71": [0, 0.68333, 0, 0],
    "72": [0, 0.68333, 0, 0],
    "73": [0, 0.68333, 0, 0],
    "74": [0, 0.68333, 0, 0],
    "75": [0, 0.68333, 0, 0],
    "76": [0, 0.68333, 0, 0],
    "77": [0, 0.68333, 0, 0],
    "78": [0, 0.68333, 0, 0],
    "79": [0, 0.68333, 0, 0],
    "80": [0, 0.68333, 0, 0],
    "81": [0.19444, 0.68333, 0, 0],
    "82": [0, 0.68333, 0, 0],
    "83": [0, 0.68333, 0, 0],
    "84": [0, 0.68333, 0, 0],
    "85": [0, 0.68333, 0, 0],
    "86": [0, 0.68333, 0.01389, 0],
    "87": [0, 0.68333, 0.01389, 0],
    "88": [0, 0.68333, 0, 0],
    "89": [0, 0.68333, 0.025, 0],
    "90": [0, 0.68333, 0, 0],
    "91": [0.25, 0.75, 0, 0],
    "92": [0.25, 0.75, 0, 0],
    "93": [0.25, 0.75, 0, 0],
    "94": [0, 0.69444, 0, 0],
    "95": [0.31, 0.12056, 0.02778, 0],
    "96": [0, 0.69444, 0, 0],
    "97": [0, 0.43056, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.43056, 0, 0],
    "100": [0, 0.69444, 0, 0],
    "101": [0, 0.43056, 0, 0],
    "102": [0, 0.69444, 0.07778, 0],
    "103": [0.19444, 0.43056, 0.01389, 0],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.66786, 0, 0],
    "106": [0.19444, 0.66786, 0, 0],
    "107": [0, 0.69444, 0, 0],
    "108": [0, 0.69444, 0, 0],
    "109": [0, 0.43056, 0, 0],
    "110": [0, 0.43056, 0, 0],
    "111": [0, 0.43056, 0, 0],
    "112": [0.19444, 0.43056, 0, 0],
    "113": [0.19444, 0.43056, 0, 0],
    "114": [0, 0.43056, 0, 0],
    "115": [0, 0.43056, 0, 0],
    "116": [0, 0.61508, 0, 0],
    "117": [0, 0.43056, 0, 0],
    "118": [0, 0.43056, 0.01389, 0],
    "119": [0, 0.43056, 0.01389, 0],
    "120": [0, 0.43056, 0, 0],
    "121": [0.19444, 0.43056, 0.01389, 0],
    "122": [0, 0.43056, 0, 0],
    "123": [0.25, 0.75, 0, 0],
    "124": [0.25, 0.75, 0, 0],
    "125": [0.25, 0.75, 0, 0],
    "126": [0.35, 0.31786, 0, 0],
    "160": [0, 0, 0, 0],
    "168": [0, 0.66786, 0, 0],
    "172": [0, 0.43056, 0, 0],
    "175": [0, 0.56778, 0, 0],
    "176": [0, 0.69444, 0, 0],
    "177": [0.08333, 0.58333, 0, 0],
    "180": [0, 0.69444, 0, 0],
    "215": [0.08333, 0.58333, 0, 0],
    "247": [0.08333, 0.58333, 0, 0],
    "305": [0, 0.43056, 0, 0],
    "567": [0.19444, 0.43056, 0, 0],
    "710": [0, 0.69444, 0, 0],
    "711": [0, 0.62847, 0, 0],
    "713": [0, 0.56778, 0, 0],
    "714": [0, 0.69444, 0, 0],
    "715": [0, 0.69444, 0, 0],
    "728": [0, 0.69444, 0, 0],
    "729": [0, 0.66786, 0, 0],
    "730": [0, 0.69444, 0, 0],
    "732": [0, 0.66786, 0, 0],
    "768": [0, 0.69444, 0, 0],
    "769": [0, 0.69444, 0, 0],
    "770": [0, 0.69444, 0, 0],
    "771": [0, 0.66786, 0, 0],
    "772": [0, 0.56778, 0, 0],
    "774": [0, 0.69444, 0, 0],
    "775": [0, 0.66786, 0, 0],
    "776": [0, 0.66786, 0, 0],
    "778": [0, 0.69444, 0, 0],
    "779": [0, 0.69444, 0, 0],
    "780": [0, 0.62847, 0, 0],
    "824": [0.19444, 0.69444, 0, 0],
    "915": [0, 0.68333, 0, 0],
    "916": [0, 0.68333, 0, 0],
    "920": [0, 0.68333, 0, 0],
    "923": [0, 0.68333, 0, 0],
    "926": [0, 0.68333, 0, 0],
    "928": [0, 0.68333, 0, 0],
    "931": [0, 0.68333, 0, 0],
    "933": [0, 0.68333, 0, 0],
    "934": [0, 0.68333, 0, 0],
    "936": [0, 0.68333, 0, 0],
    "937": [0, 0.68333, 0, 0],
    "8211": [0, 0.43056, 0.02778, 0],
    "8212": [0, 0.43056, 0.02778, 0],
    "8216": [0, 0.69444, 0, 0],
    "8217": [0, 0.69444, 0, 0],
    "8220": [0, 0.69444, 0, 0],
    "8221": [0, 0.69444, 0, 0],
    "8224": [0.19444, 0.69444, 0, 0],
    "8225": [0.19444, 0.69444, 0, 0],
    "8230": [0, 0.12, 0, 0],
    "8242": [0, 0.55556, 0, 0],
    "8407": [0, 0.71444, 0.15382, 0],
    "8463": [0, 0.68889, 0, 0],
    "8465": [0, 0.69444, 0, 0],
    "8467": [0, 0.69444, 0, 0.11111],
    "8472": [0.19444, 0.43056, 0, 0.11111],
    "8476": [0, 0.69444, 0, 0],
    "8501": [0, 0.69444, 0, 0],
    "8592": [-0.13313, 0.36687, 0, 0],
    "8593": [0.19444, 0.69444, 0, 0],
    "8594": [-0.13313, 0.36687, 0, 0],
    "8595": [0.19444, 0.69444, 0, 0],
    "8596": [-0.13313, 0.36687, 0, 0],
    "8597": [0.25, 0.75, 0, 0],
    "8598": [0.19444, 0.69444, 0, 0],
    "8599": [0.19444, 0.69444, 0, 0],
    "8600": [0.19444, 0.69444, 0, 0],
    "8601": [0.19444, 0.69444, 0, 0],
    "8614": [0.011, 0.511, 0, 0],
    "8617": [0.011, 0.511, 0, 0],
    "8618": [0.011, 0.511, 0, 0],
    "8636": [-0.13313, 0.36687, 0, 0],
    "8637": [-0.13313, 0.36687, 0, 0],
    "8640": [-0.13313, 0.36687, 0, 0],
    "8641": [-0.13313, 0.36687, 0, 0],
    "8652": [0.011, 0.671, 0, 0],
    "8656": [-0.13313, 0.36687, 0, 0],
    "8657": [0.19444, 0.69444, 0, 0],
    "8658": [-0.13313, 0.36687, 0, 0],
    "8659": [0.19444, 0.69444, 0, 0],
    "8660": [-0.13313, 0.36687, 0, 0],
    "8661": [0.25, 0.75, 0, 0],
    "8704": [0, 0.69444, 0, 0],
    "8706": [0, 0.69444, 0.05556, 0.08334],
    "8707": [0, 0.69444, 0, 0],
    "8709": [0.05556, 0.75, 0, 0],
    "8711": [0, 0.68333, 0, 0],
    "8712": [0.0391, 0.5391, 0, 0],
    "8715": [0.0391, 0.5391, 0, 0],
    "8722": [0.08333, 0.58333, 0, 0],
    "8723": [0.08333, 0.58333, 0, 0],
    "8725": [0.25, 0.75, 0, 0],
    "8726": [0.25, 0.75, 0, 0],
    "8727": [-0.03472, 0.46528, 0, 0],
    "8728": [-0.05555, 0.44445, 0, 0],
    "8729": [-0.05555, 0.44445, 0, 0],
    "8730": [0.2, 0.8, 0, 0],
    "8733": [0, 0.43056, 0, 0],
    "8734": [0, 0.43056, 0, 0],
    "8736": [0, 0.69224, 0, 0],
    "8739": [0.25, 0.75, 0, 0],
    "8741": [0.25, 0.75, 0, 0],
    "8743": [0, 0.55556, 0, 0],
    "8744": [0, 0.55556, 0, 0],
    "8745": [0, 0.55556, 0, 0],
    "8746": [0, 0.55556, 0, 0],
    "8747": [0.19444, 0.69444, 0.11111, 0],
    "8764": [-0.13313, 0.36687, 0, 0],
    "8768": [0.19444, 0.69444, 0, 0],
    "8771": [-0.03625, 0.46375, 0, 0],
    "8773": [-0.022, 0.589, 0, 0],
    "8776": [-0.01688, 0.48312, 0, 0],
    "8781": [-0.03625, 0.46375, 0, 0],
    "8784": [-0.133, 0.67, 0, 0],
    "8800": [0.215, 0.716, 0, 0],
    "8801": [-0.03625, 0.46375, 0, 0],
    "8804": [0.13597, 0.63597, 0, 0],
    "8805": [0.13597, 0.63597, 0, 0],
    "8810": [0.0391, 0.5391, 0, 0],
    "8811": [0.0391, 0.5391, 0, 0],
    "8826": [0.0391, 0.5391, 0, 0],
    "8827": [0.0391, 0.5391, 0, 0],
    "8834": [0.0391, 0.5391, 0, 0],
    "8835": [0.0391, 0.5391, 0, 0],
    "8838": [0.13597, 0.63597, 0, 0],
    "8839": [0.13597, 0.63597, 0, 0],
    "8846": [0, 0.55556, 0, 0],
    "8849": [0.13597, 0.63597, 0, 0],
    "8850": [0.13597, 0.63597, 0, 0],
    "8851": [0, 0.55556, 0, 0],
    "8852": [0, 0.55556, 0, 0],
    "8853": [0.08333, 0.58333, 0, 0],
    "8854": [0.08333, 0.58333, 0, 0],
    "8855": [0.08333, 0.58333, 0, 0],
    "8856": [0.08333, 0.58333, 0, 0],
    "8857": [0.08333, 0.58333, 0, 0],
    "8866": [0, 0.69444, 0, 0],
    "8867": [0, 0.69444, 0, 0],
    "8868": [0, 0.69444, 0, 0],
    "8869": [0, 0.69444, 0, 0],
    "8872": [0.249, 0.75, 0, 0],
    "8900": [-0.05555, 0.44445, 0, 0],
    "8901": [-0.05555, 0.44445, 0, 0],
    "8902": [-0.03472, 0.46528, 0, 0],
    "8904": [0.005, 0.505, 0, 0],
    "8942": [0.03, 0.9, 0, 0],
    "8943": [-0.19, 0.31, 0, 0],
    "8945": [-0.1, 0.82, 0, 0],
    "8968": [0.25, 0.75, 0, 0],
    "8969": [0.25, 0.75, 0, 0],
    "8970": [0.25, 0.75, 0, 0],
    "8971": [0.25, 0.75, 0, 0],
    "8994": [-0.14236, 0.35764, 0, 0],
    "8995": [-0.14236, 0.35764, 0, 0],
    "9136": [0.244, 0.744, 0, 0],
    "9137": [0.244, 0.744, 0, 0],
    "9651": [0.19444, 0.69444, 0, 0],
    "9657": [-0.03472, 0.46528, 0, 0],
    "9661": [0.19444, 0.69444, 0, 0],
    "9667": [-0.03472, 0.46528, 0, 0],
    "9711": [0.19444, 0.69444, 0, 0],
    "9824": [0.12963, 0.69444, 0, 0],
    "9825": [0.12963, 0.69444, 0, 0],
    "9826": [0.12963, 0.69444, 0, 0],
    "9827": [0.12963, 0.69444, 0, 0],
    "9837": [0, 0.75, 0, 0],
    "9838": [0.19444, 0.69444, 0, 0],
    "9839": [0.19444, 0.69444, 0, 0],
    "10216": [0.25, 0.75, 0, 0],
    "10217": [0.25, 0.75, 0, 0],
    "10222": [0.244, 0.744, 0, 0],
    "10223": [0.244, 0.744, 0, 0],
    "10229": [0.011, 0.511, 0, 0],
    "10230": [0.011, 0.511, 0, 0],
    "10231": [0.011, 0.511, 0, 0],
    "10232": [0.024, 0.525, 0, 0],
    "10233": [0.024, 0.525, 0, 0],
    "10234": [0.024, 0.525, 0, 0],
    "10236": [0.011, 0.511, 0, 0],
    "10815": [0, 0.68333, 0, 0],
    "10927": [0.13597, 0.63597, 0, 0],
    "10928": [0.13597, 0.63597, 0, 0]
  },
  "Math-BoldItalic": {
    "47": [0.19444, 0.69444, 0, 0],
    "65": [0, 0.68611, 0, 0],
    "66": [0, 0.68611, 0.04835, 0],
    "67": [0, 0.68611, 0.06979, 0],
    "68": [0, 0.68611, 0.03194, 0],
    "69": [0, 0.68611, 0.05451, 0],
    "70": [0, 0.68611, 0.15972, 0],
    "71": [0, 0.68611, 0, 0],
    "72": [0, 0.68611, 0.08229, 0],
    "73": [0, 0.68611, 0.07778, 0],
    "74": [0, 0.68611, 0.10069, 0],
    "75": [0, 0.68611, 0.06979, 0],
    "76": [0, 0.68611, 0, 0],
    "77": [0, 0.68611, 0.11424, 0],
    "78": [0, 0.68611, 0.11424, 0],
    "79": [0, 0.68611, 0.03194, 0],
    "80": [0, 0.68611, 0.15972, 0],
    "81": [0.19444, 0.68611, 0, 0],
    "82": [0, 0.68611, 0.00421, 0],
    "83": [0, 0.68611, 0.05382, 0],
    "84": [0, 0.68611, 0.15972, 0],
    "85": [0, 0.68611, 0.11424, 0],
    "86": [0, 0.68611, 0.25555, 0],
    "87": [0, 0.68611, 0.15972, 0],
    "88": [0, 0.68611, 0.07778, 0],
    "89": [0, 0.68611, 0.25555, 0],
    "90": [0, 0.68611, 0.06979, 0],
    "97": [0, 0.44444, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.44444, 0, 0],
    "100": [0, 0.69444, 0, 0],
    "101": [0, 0.44444, 0, 0],
    "102": [0.19444, 0.69444, 0.11042, 0],
    "103": [0.19444, 0.44444, 0.03704, 0],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.69326, 0, 0],
    "106": [0.19444, 0.69326, 0.0622, 0],
    "107": [0, 0.69444, 0.01852, 0],
    "108": [0, 0.69444, 0.0088, 0],
    "109": [0, 0.44444, 0, 0],
    "110": [0, 0.44444, 0, 0],
    "111": [0, 0.44444, 0, 0],
    "112": [0.19444, 0.44444, 0, 0],
    "113": [0.19444, 0.44444, 0.03704, 0],
    "114": [0, 0.44444, 0.03194, 0],
    "115": [0, 0.44444, 0, 0],
    "116": [0, 0.63492, 0, 0],
    "117": [0, 0.44444, 0, 0],
    "118": [0, 0.44444, 0.03704, 0],
    "119": [0, 0.44444, 0.02778, 0],
    "120": [0, 0.44444, 0, 0],
    "121": [0.19444, 0.44444, 0.03704, 0],
    "122": [0, 0.44444, 0.04213, 0],
    "915": [0, 0.68611, 0.15972, 0],
    "916": [0, 0.68611, 0, 0],
    "920": [0, 0.68611, 0.03194, 0],
    "923": [0, 0.68611, 0, 0],
    "926": [0, 0.68611, 0.07458, 0],
    "928": [0, 0.68611, 0.08229, 0],
    "931": [0, 0.68611, 0.05451, 0],
    "933": [0, 0.68611, 0.15972, 0],
    "934": [0, 0.68611, 0, 0],
    "936": [0, 0.68611, 0.11653, 0],
    "937": [0, 0.68611, 0.04835, 0],
    "945": [0, 0.44444, 0, 0],
    "946": [0.19444, 0.69444, 0.03403, 0],
    "947": [0.19444, 0.44444, 0.06389, 0],
    "948": [0, 0.69444, 0.03819, 0],
    "949": [0, 0.44444, 0, 0],
    "950": [0.19444, 0.69444, 0.06215, 0],
    "951": [0.19444, 0.44444, 0.03704, 0],
    "952": [0, 0.69444, 0.03194, 0],
    "953": [0, 0.44444, 0, 0],
    "954": [0, 0.44444, 0, 0],
    "955": [0, 0.69444, 0, 0],
    "956": [0.19444, 0.44444, 0, 0],
    "957": [0, 0.44444, 0.06898, 0],
    "958": [0.19444, 0.69444, 0.03021, 0],
    "959": [0, 0.44444, 0, 0],
    "960": [0, 0.44444, 0.03704, 0],
    "961": [0.19444, 0.44444, 0, 0],
    "962": [0.09722, 0.44444, 0.07917, 0],
    "963": [0, 0.44444, 0.03704, 0],
    "964": [0, 0.44444, 0.13472, 0],
    "965": [0, 0.44444, 0.03704, 0],
    "966": [0.19444, 0.44444, 0, 0],
    "967": [0.19444, 0.44444, 0, 0],
    "968": [0.19444, 0.69444, 0.03704, 0],
    "969": [0, 0.44444, 0.03704, 0],
    "977": [0, 0.69444, 0, 0],
    "981": [0.19444, 0.69444, 0, 0],
    "982": [0, 0.44444, 0.03194, 0],
    "1009": [0.19444, 0.44444, 0, 0],
    "1013": [0, 0.44444, 0, 0]
  },
  "Math-Italic": {
    "47": [0.19444, 0.69444, 0, 0],
    "65": [0, 0.68333, 0, 0.13889],
    "66": [0, 0.68333, 0.05017, 0.08334],
    "67": [0, 0.68333, 0.07153, 0.08334],
    "68": [0, 0.68333, 0.02778, 0.05556],
    "69": [0, 0.68333, 0.05764, 0.08334],
    "70": [0, 0.68333, 0.13889, 0.08334],
    "71": [0, 0.68333, 0, 0.08334],
    "72": [0, 0.68333, 0.08125, 0.05556],
    "73": [0, 0.68333, 0.07847, 0.11111],
    "74": [0, 0.68333, 0.09618, 0.16667],
    "75": [0, 0.68333, 0.07153, 0.05556],
    "76": [0, 0.68333, 0, 0.02778],
    "77": [0, 0.68333, 0.10903, 0.08334],
    "78": [0, 0.68333, 0.10903, 0.08334],
    "79": [0, 0.68333, 0.02778, 0.08334],
    "80": [0, 0.68333, 0.13889, 0.08334],
    "81": [0.19444, 0.68333, 0, 0.08334],
    "82": [0, 0.68333, 0.00773, 0.08334],
    "83": [0, 0.68333, 0.05764, 0.08334],
    "84": [0, 0.68333, 0.13889, 0.08334],
    "85": [0, 0.68333, 0.10903, 0.02778],
    "86": [0, 0.68333, 0.22222, 0],
    "87": [0, 0.68333, 0.13889, 0],
    "88": [0, 0.68333, 0.07847, 0.08334],
    "89": [0, 0.68333, 0.22222, 0],
    "90": [0, 0.68333, 0.07153, 0.08334],
    "97": [0, 0.43056, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.43056, 0, 0.05556],
    "100": [0, 0.69444, 0, 0.16667],
    "101": [0, 0.43056, 0, 0.05556],
    "102": [0.19444, 0.69444, 0.10764, 0.16667],
    "103": [0.19444, 0.43056, 0.03588, 0.02778],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.65952, 0, 0],
    "106": [0.19444, 0.65952, 0.05724, 0],
    "107": [0, 0.69444, 0.03148, 0],
    "108": [0, 0.69444, 0.01968, 0.08334],
    "109": [0, 0.43056, 0, 0],
    "110": [0, 0.43056, 0, 0],
    "111": [0, 0.43056, 0, 0.05556],
    "112": [0.19444, 0.43056, 0, 0.08334],
    "113": [0.19444, 0.43056, 0.03588, 0.08334],
    "114": [0, 0.43056, 0.02778, 0.05556],
    "115": [0, 0.43056, 0, 0.05556],
    "116": [0, 0.61508, 0, 0.08334],
    "117": [0, 0.43056, 0, 0.02778],
    "118": [0, 0.43056, 0.03588, 0.02778],
    "119": [0, 0.43056, 0.02691, 0.08334],
    "120": [0, 0.43056, 0, 0.02778],
    "121": [0.19444, 0.43056, 0.03588, 0.05556],
    "122": [0, 0.43056, 0.04398, 0.05556],
    "915": [0, 0.68333, 0.13889, 0.08334],
    "916": [0, 0.68333, 0, 0.16667],
    "920": [0, 0.68333, 0.02778, 0.08334],
    "923": [0, 0.68333, 0, 0.16667],
    "926": [0, 0.68333, 0.07569, 0.08334],
    "928": [0, 0.68333, 0.08125, 0.05556],
    "931": [0, 0.68333, 0.05764, 0.08334],
    "933": [0, 0.68333, 0.13889, 0.05556],
    "934": [0, 0.68333, 0, 0.08334],
    "936": [0, 0.68333, 0.11, 0.05556],
    "937": [0, 0.68333, 0.05017, 0.08334],
    "945": [0, 0.43056, 0.0037, 0.02778],
    "946": [0.19444, 0.69444, 0.05278, 0.08334],
    "947": [0.19444, 0.43056, 0.05556, 0],
    "948": [0, 0.69444, 0.03785, 0.05556],
    "949": [0, 0.43056, 0, 0.08334],
    "950": [0.19444, 0.69444, 0.07378, 0.08334],
    "951": [0.19444, 0.43056, 0.03588, 0.05556],
    "952": [0, 0.69444, 0.02778, 0.08334],
    "953": [0, 0.43056, 0, 0.05556],
    "954": [0, 0.43056, 0, 0],
    "955": [0, 0.69444, 0, 0],
    "956": [0.19444, 0.43056, 0, 0.02778],
    "957": [0, 0.43056, 0.06366, 0.02778],
    "958": [0.19444, 0.69444, 0.04601, 0.11111],
    "959": [0, 0.43056, 0, 0.05556],
    "960": [0, 0.43056, 0.03588, 0],
    "961": [0.19444, 0.43056, 0, 0.08334],
    "962": [0.09722, 0.43056, 0.07986, 0.08334],
    "963": [0, 0.43056, 0.03588, 0],
    "964": [0, 0.43056, 0.1132, 0.02778],
    "965": [0, 0.43056, 0.03588, 0.02778],
    "966": [0.19444, 0.43056, 0, 0.08334],
    "967": [0.19444, 0.43056, 0, 0.05556],
    "968": [0.19444, 0.69444, 0.03588, 0.11111],
    "969": [0, 0.43056, 0.03588, 0],
    "977": [0, 0.69444, 0, 0.08334],
    "981": [0.19444, 0.69444, 0, 0.08334],
    "982": [0, 0.43056, 0.02778, 0],
    "1009": [0.19444, 0.43056, 0, 0.08334],
    "1013": [0, 0.43056, 0, 0.05556]
  },
  "Math-Regular": {
    "65": [0, 0.68333, 0, 0.13889],
    "66": [0, 0.68333, 0.05017, 0.08334],
    "67": [0, 0.68333, 0.07153, 0.08334],
    "68": [0, 0.68333, 0.02778, 0.05556],
    "69": [0, 0.68333, 0.05764, 0.08334],
    "70": [0, 0.68333, 0.13889, 0.08334],
    "71": [0, 0.68333, 0, 0.08334],
    "72": [0, 0.68333, 0.08125, 0.05556],
    "73": [0, 0.68333, 0.07847, 0.11111],
    "74": [0, 0.68333, 0.09618, 0.16667],
    "75": [0, 0.68333, 0.07153, 0.05556],
    "76": [0, 0.68333, 0, 0.02778],
    "77": [0, 0.68333, 0.10903, 0.08334],
    "78": [0, 0.68333, 0.10903, 0.08334],
    "79": [0, 0.68333, 0.02778, 0.08334],
    "80": [0, 0.68333, 0.13889, 0.08334],
    "81": [0.19444, 0.68333, 0, 0.08334],
    "82": [0, 0.68333, 0.00773, 0.08334],
    "83": [0, 0.68333, 0.05764, 0.08334],
    "84": [0, 0.68333, 0.13889, 0.08334],
    "85": [0, 0.68333, 0.10903, 0.02778],
    "86": [0, 0.68333, 0.22222, 0],
    "87": [0, 0.68333, 0.13889, 0],
    "88": [0, 0.68333, 0.07847, 0.08334],
    "89": [0, 0.68333, 0.22222, 0],
    "90": [0, 0.68333, 0.07153, 0.08334],
    "97": [0, 0.43056, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.43056, 0, 0.05556],
    "100": [0, 0.69444, 0, 0.16667],
    "101": [0, 0.43056, 0, 0.05556],
    "102": [0.19444, 0.69444, 0.10764, 0.16667],
    "103": [0.19444, 0.43056, 0.03588, 0.02778],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.65952, 0, 0],
    "106": [0.19444, 0.65952, 0.05724, 0],
    "107": [0, 0.69444, 0.03148, 0],
    "108": [0, 0.69444, 0.01968, 0.08334],
    "109": [0, 0.43056, 0, 0],
    "110": [0, 0.43056, 0, 0],
    "111": [0, 0.43056, 0, 0.05556],
    "112": [0.19444, 0.43056, 0, 0.08334],
    "113": [0.19444, 0.43056, 0.03588, 0.08334],
    "114": [0, 0.43056, 0.02778, 0.05556],
    "115": [0, 0.43056, 0, 0.05556],
    "116": [0, 0.61508, 0, 0.08334],
    "117": [0, 0.43056, 0, 0.02778],
    "118": [0, 0.43056, 0.03588, 0.02778],
    "119": [0, 0.43056, 0.02691, 0.08334],
    "120": [0, 0.43056, 0, 0.02778],
    "121": [0.19444, 0.43056, 0.03588, 0.05556],
    "122": [0, 0.43056, 0.04398, 0.05556],
    "915": [0, 0.68333, 0.13889, 0.08334],
    "916": [0, 0.68333, 0, 0.16667],
    "920": [0, 0.68333, 0.02778, 0.08334],
    "923": [0, 0.68333, 0, 0.16667],
    "926": [0, 0.68333, 0.07569, 0.08334],
    "928": [0, 0.68333, 0.08125, 0.05556],
    "931": [0, 0.68333, 0.05764, 0.08334],
    "933": [0, 0.68333, 0.13889, 0.05556],
    "934": [0, 0.68333, 0, 0.08334],
    "936": [0, 0.68333, 0.11, 0.05556],
    "937": [0, 0.68333, 0.05017, 0.08334],
    "945": [0, 0.43056, 0.0037, 0.02778],
    "946": [0.19444, 0.69444, 0.05278, 0.08334],
    "947": [0.19444, 0.43056, 0.05556, 0],
    "948": [0, 0.69444, 0.03785, 0.05556],
    "949": [0, 0.43056, 0, 0.08334],
    "950": [0.19444, 0.69444, 0.07378, 0.08334],
    "951": [0.19444, 0.43056, 0.03588, 0.05556],
    "952": [0, 0.69444, 0.02778, 0.08334],
    "953": [0, 0.43056, 0, 0.05556],
    "954": [0, 0.43056, 0, 0],
    "955": [0, 0.69444, 0, 0],
    "956": [0.19444, 0.43056, 0, 0.02778],
    "957": [0, 0.43056, 0.06366, 0.02778],
    "958": [0.19444, 0.69444, 0.04601, 0.11111],
    "959": [0, 0.43056, 0, 0.05556],
    "960": [0, 0.43056, 0.03588, 0],
    "961": [0.19444, 0.43056, 0, 0.08334],
    "962": [0.09722, 0.43056, 0.07986, 0.08334],
    "963": [0, 0.43056, 0.03588, 0],
    "964": [0, 0.43056, 0.1132, 0.02778],
    "965": [0, 0.43056, 0.03588, 0.02778],
    "966": [0.19444, 0.43056, 0, 0.08334],
    "967": [0.19444, 0.43056, 0, 0.05556],
    "968": [0.19444, 0.69444, 0.03588, 0.11111],
    "969": [0, 0.43056, 0.03588, 0],
    "977": [0, 0.69444, 0, 0.08334],
    "981": [0.19444, 0.69444, 0, 0.08334],
    "982": [0, 0.43056, 0.02778, 0],
    "1009": [0.19444, 0.43056, 0, 0.08334],
    "1013": [0, 0.43056, 0, 0.05556]
  },
  "SansSerif-Regular": {
    "33": [0, 0.69444, 0, 0],
    "34": [0, 0.69444, 0, 0],
    "35": [0.19444, 0.69444, 0, 0],
    "36": [0.05556, 0.75, 0, 0],
    "37": [0.05556, 0.75, 0, 0],
    "38": [0, 0.69444, 0, 0],
    "39": [0, 0.69444, 0, 0],
    "40": [0.25, 0.75, 0, 0],
    "41": [0.25, 0.75, 0, 0],
    "42": [0, 0.75, 0, 0],
    "43": [0.08333, 0.58333, 0, 0],
    "44": [0.125, 0.08333, 0, 0],
    "45": [0, 0.44444, 0, 0],
    "46": [0, 0.08333, 0, 0],
    "47": [0.25, 0.75, 0, 0],
    "48": [0, 0.65556, 0, 0],
    "49": [0, 0.65556, 0, 0],
    "50": [0, 0.65556, 0, 0],
    "51": [0, 0.65556, 0, 0],
    "52": [0, 0.65556, 0, 0],
    "53": [0, 0.65556, 0, 0],
    "54": [0, 0.65556, 0, 0],
    "55": [0, 0.65556, 0, 0],
    "56": [0, 0.65556, 0, 0],
    "57": [0, 0.65556, 0, 0],
    "58": [0, 0.44444, 0, 0],
    "59": [0.125, 0.44444, 0, 0],
    "61": [-0.13, 0.37, 0, 0],
    "63": [0, 0.69444, 0, 0],
    "64": [0, 0.69444, 0, 0],
    "65": [0, 0.69444, 0, 0],
    "66": [0, 0.69444, 0, 0],
    "67": [0, 0.69444, 0, 0],
    "68": [0, 0.69444, 0, 0],
    "69": [0, 0.69444, 0, 0],
    "70": [0, 0.69444, 0, 0],
    "71": [0, 0.69444, 0, 0],
    "72": [0, 0.69444, 0, 0],
    "73": [0, 0.69444, 0, 0],
    "74": [0, 0.69444, 0, 0],
    "75": [0, 0.69444, 0, 0],
    "76": [0, 0.69444, 0, 0],
    "77": [0, 0.69444, 0, 0],
    "78": [0, 0.69444, 0, 0],
    "79": [0, 0.69444, 0, 0],
    "80": [0, 0.69444, 0, 0],
    "81": [0.125, 0.69444, 0, 0],
    "82": [0, 0.69444, 0, 0],
    "83": [0, 0.69444, 0, 0],
    "84": [0, 0.69444, 0, 0],
    "85": [0, 0.69444, 0, 0],
    "86": [0, 0.69444, 0.01389, 0],
    "87": [0, 0.69444, 0.01389, 0],
    "88": [0, 0.69444, 0, 0],
    "89": [0, 0.69444, 0.025, 0],
    "90": [0, 0.69444, 0, 0],
    "91": [0.25, 0.75, 0, 0],
    "93": [0.25, 0.75, 0, 0],
    "94": [0, 0.69444, 0, 0],
    "95": [0.35, 0.09444, 0.02778, 0],
    "97": [0, 0.44444, 0, 0],
    "98": [0, 0.69444, 0, 0],
    "99": [0, 0.44444, 0, 0],
    "100": [0, 0.69444, 0, 0],
    "101": [0, 0.44444, 0, 0],
    "102": [0, 0.69444, 0.06944, 0],
    "103": [0.19444, 0.44444, 0.01389, 0],
    "104": [0, 0.69444, 0, 0],
    "105": [0, 0.67937, 0, 0],
    "106": [0.19444, 0.67937, 0, 0],
    "107": [0, 0.69444, 0, 0],
    "108": [0, 0.69444, 0, 0],
    "109": [0, 0.44444, 0, 0],
    "110": [0, 0.44444, 0, 0],
    "111": [0, 0.44444, 0, 0],
    "112": [0.19444, 0.44444, 0, 0],
    "113": [0.19444, 0.44444, 0, 0],
    "114": [0, 0.44444, 0.01389, 0],
    "115": [0, 0.44444, 0, 0],
    "116": [0, 0.57143, 0, 0],
    "117": [0, 0.44444, 0, 0],
    "118": [0, 0.44444, 0.01389, 0],
    "119": [0, 0.44444, 0.01389, 0],
    "120": [0, 0.44444, 0, 0],
    "121": [0.19444, 0.44444, 0.01389, 0],
    "122": [0, 0.44444, 0, 0],
    "126": [0.35, 0.32659, 0, 0],
    "305": [0, 0.44444, 0, 0],
    "567": [0.19444, 0.44444, 0, 0],
    "768": [0, 0.69444, 0, 0],
    "769": [0, 0.69444, 0, 0],
    "770": [0, 0.69444, 0, 0],
    "771": [0, 0.67659, 0, 0],
    "772": [0, 0.60889, 0, 0],
    "774": [0, 0.69444, 0, 0],
    "775": [0, 0.67937, 0, 0],
    "776": [0, 0.67937, 0, 0],
    "778": [0, 0.69444, 0, 0],
    "779": [0, 0.69444, 0, 0],
    "780": [0, 0.63194, 0, 0],
    "915": [0, 0.69444, 0, 0],
    "916": [0, 0.69444, 0, 0],
    "920": [0, 0.69444, 0, 0],
    "923": [0, 0.69444, 0, 0],
    "926": [0, 0.69444, 0, 0],
    "928": [0, 0.69444, 0, 0],
    "931": [0, 0.69444, 0, 0],
    "933": [0, 0.69444, 0, 0],
    "934": [0, 0.69444, 0, 0],
    "936": [0, 0.69444, 0, 0],
    "937": [0, 0.69444, 0, 0],
    "8211": [0, 0.44444, 0.02778, 0],
    "8212": [0, 0.44444, 0.02778, 0],
    "8216": [0, 0.69444, 0, 0],
    "8217": [0, 0.69444, 0, 0],
    "8220": [0, 0.69444, 0, 0],
    "8221": [0, 0.69444, 0, 0]
  },
  "Script-Regular": {
    "65": [0, 0.7, 0.22925, 0],
    "66": [0, 0.7, 0.04087, 0],
    "67": [0, 0.7, 0.1689, 0],
    "68": [0, 0.7, 0.09371, 0],
    "69": [0, 0.7, 0.18583, 0],
    "70": [0, 0.7, 0.13634, 0],
    "71": [0, 0.7, 0.17322, 0],
    "72": [0, 0.7, 0.29694, 0],
    "73": [0, 0.7, 0.19189, 0],
    "74": [0.27778, 0.7, 0.19189, 0],
    "75": [0, 0.7, 0.31259, 0],
    "76": [0, 0.7, 0.19189, 0],
    "77": [0, 0.7, 0.15981, 0],
    "78": [0, 0.7, 0.3525, 0],
    "79": [0, 0.7, 0.08078, 0],
    "80": [0, 0.7, 0.08078, 0],
    "81": [0, 0.7, 0.03305, 0],
    "82": [0, 0.7, 0.06259, 0],
    "83": [0, 0.7, 0.19189, 0],
    "84": [0, 0.7, 0.29087, 0],
    "85": [0, 0.7, 0.25815, 0],
    "86": [0, 0.7, 0.27523, 0],
    "87": [0, 0.7, 0.27523, 0],
    "88": [0, 0.7, 0.26006, 0],
    "89": [0, 0.7, 0.2939, 0],
    "90": [0, 0.7, 0.24037, 0]
  },
  "Size1-Regular": {
    "40": [0.35001, 0.85, 0, 0],
    "41": [0.35001, 0.85, 0, 0],
    "47": [0.35001, 0.85, 0, 0],
    "91": [0.35001, 0.85, 0, 0],
    "92": [0.35001, 0.85, 0, 0],
    "93": [0.35001, 0.85, 0, 0],
    "123": [0.35001, 0.85, 0, 0],
    "125": [0.35001, 0.85, 0, 0],
    "710": [0, 0.72222, 0, 0],
    "732": [0, 0.72222, 0, 0],
    "770": [0, 0.72222, 0, 0],
    "771": [0, 0.72222, 0, 0],
    "8214": [-0.00099, 0.601, 0, 0],
    "8593": [1e-05, 0.6, 0, 0],
    "8595": [1e-05, 0.6, 0, 0],
    "8657": [1e-05, 0.6, 0, 0],
    "8659": [1e-05, 0.6, 0, 0],
    "8719": [0.25001, 0.75, 0, 0],
    "8720": [0.25001, 0.75, 0, 0],
    "8721": [0.25001, 0.75, 0, 0],
    "8730": [0.35001, 0.85, 0, 0],
    "8739": [-0.00599, 0.606, 0, 0],
    "8741": [-0.00599, 0.606, 0, 0],
    "8747": [0.30612, 0.805, 0.19445, 0],
    "8748": [0.306, 0.805, 0.19445, 0],
    "8749": [0.306, 0.805, 0.19445, 0],
    "8750": [0.30612, 0.805, 0.19445, 0],
    "8896": [0.25001, 0.75, 0, 0],
    "8897": [0.25001, 0.75, 0, 0],
    "8898": [0.25001, 0.75, 0, 0],
    "8899": [0.25001, 0.75, 0, 0],
    "8968": [0.35001, 0.85, 0, 0],
    "8969": [0.35001, 0.85, 0, 0],
    "8970": [0.35001, 0.85, 0, 0],
    "8971": [0.35001, 0.85, 0, 0],
    "9168": [-0.00099, 0.601, 0, 0],
    "10216": [0.35001, 0.85, 0, 0],
    "10217": [0.35001, 0.85, 0, 0],
    "10752": [0.25001, 0.75, 0, 0],
    "10753": [0.25001, 0.75, 0, 0],
    "10754": [0.25001, 0.75, 0, 0],
    "10756": [0.25001, 0.75, 0, 0],
    "10758": [0.25001, 0.75, 0, 0]
  },
  "Size2-Regular": {
    "40": [0.65002, 1.15, 0, 0],
    "41": [0.65002, 1.15, 0, 0],
    "47": [0.65002, 1.15, 0, 0],
    "91": [0.65002, 1.15, 0, 0],
    "92": [0.65002, 1.15, 0, 0],
    "93": [0.65002, 1.15, 0, 0],
    "123": [0.65002, 1.15, 0, 0],
    "125": [0.65002, 1.15, 0, 0],
    "710": [0, 0.75, 0, 0],
    "732": [0, 0.75, 0, 0],
    "770": [0, 0.75, 0, 0],
    "771": [0, 0.75, 0, 0],
    "8719": [0.55001, 1.05, 0, 0],
    "8720": [0.55001, 1.05, 0, 0],
    "8721": [0.55001, 1.05, 0, 0],
    "8730": [0.65002, 1.15, 0, 0],
    "8747": [0.86225, 1.36, 0.44445, 0],
    "8748": [0.862, 1.36, 0.44445, 0],
    "8749": [0.862, 1.36, 0.44445, 0],
    "8750": [0.86225, 1.36, 0.44445, 0],
    "8896": [0.55001, 1.05, 0, 0],
    "8897": [0.55001, 1.05, 0, 0],
    "8898": [0.55001, 1.05, 0, 0],
    "8899": [0.55001, 1.05, 0, 0],
    "8968": [0.65002, 1.15, 0, 0],
    "8969": [0.65002, 1.15, 0, 0],
    "8970": [0.65002, 1.15, 0, 0],
    "8971": [0.65002, 1.15, 0, 0],
    "10216": [0.65002, 1.15, 0, 0],
    "10217": [0.65002, 1.15, 0, 0],
    "10752": [0.55001, 1.05, 0, 0],
    "10753": [0.55001, 1.05, 0, 0],
    "10754": [0.55001, 1.05, 0, 0],
    "10756": [0.55001, 1.05, 0, 0],
    "10758": [0.55001, 1.05, 0, 0]
  },
  "Size3-Regular": {
    "40": [0.95003, 1.45, 0, 0],
    "41": [0.95003, 1.45, 0, 0],
    "47": [0.95003, 1.45, 0, 0],
    "91": [0.95003, 1.45, 0, 0],
    "92": [0.95003, 1.45, 0, 0],
    "93": [0.95003, 1.45, 0, 0],
    "123": [0.95003, 1.45, 0, 0],
    "125": [0.95003, 1.45, 0, 0],
    "710": [0, 0.75, 0, 0],
    "732": [0, 0.75, 0, 0],
    "770": [0, 0.75, 0, 0],
    "771": [0, 0.75, 0, 0],
    "8730": [0.95003, 1.45, 0, 0],
    "8968": [0.95003, 1.45, 0, 0],
    "8969": [0.95003, 1.45, 0, 0],
    "8970": [0.95003, 1.45, 0, 0],
    "8971": [0.95003, 1.45, 0, 0],
    "10216": [0.95003, 1.45, 0, 0],
    "10217": [0.95003, 1.45, 0, 0]
  },
  "Size4-Regular": {
    "40": [1.25003, 1.75, 0, 0],
    "41": [1.25003, 1.75, 0, 0],
    "47": [1.25003, 1.75, 0, 0],
    "91": [1.25003, 1.75, 0, 0],
    "92": [1.25003, 1.75, 0, 0],
    "93": [1.25003, 1.75, 0, 0],
    "123": [1.25003, 1.75, 0, 0],
    "125": [1.25003, 1.75, 0, 0],
    "710": [0, 0.825, 0, 0],
    "732": [0, 0.825, 0, 0],
    "770": [0, 0.825, 0, 0],
    "771": [0, 0.825, 0, 0],
    "8730": [1.25003, 1.75, 0, 0],
    "8968": [1.25003, 1.75, 0, 0],
    "8969": [1.25003, 1.75, 0, 0],
    "8970": [1.25003, 1.75, 0, 0],
    "8971": [1.25003, 1.75, 0, 0],
    "9115": [0.64502, 1.155, 0, 0],
    "9116": [1e-05, 0.6, 0, 0],
    "9117": [0.64502, 1.155, 0, 0],
    "9118": [0.64502, 1.155, 0, 0],
    "9119": [1e-05, 0.6, 0, 0],
    "9120": [0.64502, 1.155, 0, 0],
    "9121": [0.64502, 1.155, 0, 0],
    "9122": [-0.00099, 0.601, 0, 0],
    "9123": [0.64502, 1.155, 0, 0],
    "9124": [0.64502, 1.155, 0, 0],
    "9125": [-0.00099, 0.601, 0, 0],
    "9126": [0.64502, 1.155, 0, 0],
    "9127": [1e-05, 0.9, 0, 0],
    "9128": [0.65002, 1.15, 0, 0],
    "9129": [0.90001, 0, 0, 0],
    "9130": [0, 0.3, 0, 0],
    "9131": [1e-05, 0.9, 0, 0],
    "9132": [0.65002, 1.15, 0, 0],
    "9133": [0.90001, 0, 0, 0],
    "9143": [0.88502, 0.915, 0, 0],
    "10216": [1.25003, 1.75, 0, 0],
    "10217": [1.25003, 1.75, 0, 0],
    "57344": [-0.00499, 0.605, 0, 0],
    "57345": [-0.00499, 0.605, 0, 0],
    "57680": [0, 0.12, 0, 0],
    "57681": [0, 0.12, 0, 0],
    "57682": [0, 0.12, 0, 0],
    "57683": [0, 0.12, 0, 0]
  },
  "Typewriter-Regular": {
    "33": [0, 0.61111, 0, 0],
    "34": [0, 0.61111, 0, 0],
    "35": [0, 0.61111, 0, 0],
    "36": [0.08333, 0.69444, 0, 0],
    "37": [0.08333, 0.69444, 0, 0],
    "38": [0, 0.61111, 0, 0],
    "39": [0, 0.61111, 0, 0],
    "40": [0.08333, 0.69444, 0, 0],
    "41": [0.08333, 0.69444, 0, 0],
    "42": [0, 0.52083, 0, 0],
    "43": [-0.08056, 0.53055, 0, 0],
    "44": [0.13889, 0.125, 0, 0],
    "45": [-0.08056, 0.53055, 0, 0],
    "46": [0, 0.125, 0, 0],
    "47": [0.08333, 0.69444, 0, 0],
    "48": [0, 0.61111, 0, 0],
    "49": [0, 0.61111, 0, 0],
    "50": [0, 0.61111, 0, 0],
    "51": [0, 0.61111, 0, 0],
    "52": [0, 0.61111, 0, 0],
    "53": [0, 0.61111, 0, 0],
    "54": [0, 0.61111, 0, 0],
    "55": [0, 0.61111, 0, 0],
    "56": [0, 0.61111, 0, 0],
    "57": [0, 0.61111, 0, 0],
    "58": [0, 0.43056, 0, 0],
    "59": [0.13889, 0.43056, 0, 0],
    "60": [-0.05556, 0.55556, 0, 0],
    "61": [-0.19549, 0.41562, 0, 0],
    "62": [-0.05556, 0.55556, 0, 0],
    "63": [0, 0.61111, 0, 0],
    "64": [0, 0.61111, 0, 0],
    "65": [0, 0.61111, 0, 0],
    "66": [0, 0.61111, 0, 0],
    "67": [0, 0.61111, 0, 0],
    "68": [0, 0.61111, 0, 0],
    "69": [0, 0.61111, 0, 0],
    "70": [0, 0.61111, 0, 0],
    "71": [0, 0.61111, 0, 0],
    "72": [0, 0.61111, 0, 0],
    "73": [0, 0.61111, 0, 0],
    "74": [0, 0.61111, 0, 0],
    "75": [0, 0.61111, 0, 0],
    "76": [0, 0.61111, 0, 0],
    "77": [0, 0.61111, 0, 0],
    "78": [0, 0.61111, 0, 0],
    "79": [0, 0.61111, 0, 0],
    "80": [0, 0.61111, 0, 0],
    "81": [0.13889, 0.61111, 0, 0],
    "82": [0, 0.61111, 0, 0],
    "83": [0, 0.61111, 0, 0],
    "84": [0, 0.61111, 0, 0],
    "85": [0, 0.61111, 0, 0],
    "86": [0, 0.61111, 0, 0],
    "87": [0, 0.61111, 0, 0],
    "88": [0, 0.61111, 0, 0],
    "89": [0, 0.61111, 0, 0],
    "90": [0, 0.61111, 0, 0],
    "91": [0.08333, 0.69444, 0, 0],
    "92": [0.08333, 0.69444, 0, 0],
    "93": [0.08333, 0.69444, 0, 0],
    "94": [0, 0.61111, 0, 0],
    "95": [0.09514, 0, 0, 0],
    "96": [0, 0.61111, 0, 0],
    "97": [0, 0.43056, 0, 0],
    "98": [0, 0.61111, 0, 0],
    "99": [0, 0.43056, 0, 0],
    "100": [0, 0.61111, 0, 0],
    "101": [0, 0.43056, 0, 0],
    "102": [0, 0.61111, 0, 0],
    "103": [0.22222, 0.43056, 0, 0],
    "104": [0, 0.61111, 0, 0],
    "105": [0, 0.61111, 0, 0],
    "106": [0.22222, 0.61111, 0, 0],
    "107": [0, 0.61111, 0, 0],
    "108": [0, 0.61111, 0, 0],
    "109": [0, 0.43056, 0, 0],
    "110": [0, 0.43056, 0, 0],
    "111": [0, 0.43056, 0, 0],
    "112": [0.22222, 0.43056, 0, 0],
    "113": [0.22222, 0.43056, 0, 0],
    "114": [0, 0.43056, 0, 0],
    "115": [0, 0.43056, 0, 0],
    "116": [0, 0.55358, 0, 0],
    "117": [0, 0.43056, 0, 0],
    "118": [0, 0.43056, 0, 0],
    "119": [0, 0.43056, 0, 0],
    "120": [0, 0.43056, 0, 0],
    "121": [0.22222, 0.43056, 0, 0],
    "122": [0, 0.43056, 0, 0],
    "123": [0.08333, 0.69444, 0, 0],
    "124": [0.08333, 0.69444, 0, 0],
    "125": [0.08333, 0.69444, 0, 0],
    "126": [0, 0.61111, 0, 0],
    "127": [0, 0.61111, 0, 0],
    "305": [0, 0.43056, 0, 0],
    "567": [0.22222, 0.43056, 0, 0],
    "768": [0, 0.61111, 0, 0],
    "769": [0, 0.61111, 0, 0],
    "770": [0, 0.61111, 0, 0],
    "771": [0, 0.61111, 0, 0],
    "772": [0, 0.56555, 0, 0],
    "774": [0, 0.61111, 0, 0],
    "776": [0, 0.61111, 0, 0],
    "778": [0, 0.61111, 0, 0],
    "780": [0, 0.56597, 0, 0],
    "915": [0, 0.61111, 0, 0],
    "916": [0, 0.61111, 0, 0],
    "920": [0, 0.61111, 0, 0],
    "923": [0, 0.61111, 0, 0],
    "926": [0, 0.61111, 0, 0],
    "928": [0, 0.61111, 0, 0],
    "931": [0, 0.61111, 0, 0],
    "933": [0, 0.61111, 0, 0],
    "934": [0, 0.61111, 0, 0],
    "936": [0, 0.61111, 0, 0],
    "937": [0, 0.61111, 0, 0],
    "2018": [0, 0.61111, 0, 0],
    "2019": [0, 0.61111, 0, 0],
    "8242": [0, 0.61111, 0, 0]
  }
};