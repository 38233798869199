/**
 * This file contains information about the options that the Parser carries
 * around with it while parsing. Data is held in an `Options` object, and when
 * recursing, a new `Options` object can be created with the `.with*` and
 * `.reset` functions.
 */

/**
 * This is the main options class. It contains the style, size, color, and font
 * of the current parse level. It also contains the style and size of the parent
 * parse level, so size changes can be handled efficiently.
 *
 * Each of the `.with*` and `.reset` functions passes its current style and size
 * as the parentStyle and parentSize of the new options class, so parent
 * handling is taken care of automatically.
 */
function Options(data) {
  this.style = data.style;
  this.color = data.color;
  this.size = data.size;
  this.phantom = data.phantom;
  this.font = data.font;
  if (data.parentStyle === undefined) {
    this.parentStyle = data.style;
  } else {
    this.parentStyle = data.parentStyle;
  }
  if (data.parentSize === undefined) {
    this.parentSize = data.size;
  } else {
    this.parentSize = data.parentSize;
  }
}

/**
 * Returns a new options object with the same properties as "this".  Properties
 * from "extension" will be copied to the new options object.
 */
Options.prototype.extend = function (extension) {
  var data = {
    style: this.style,
    size: this.size,
    color: this.color,
    parentStyle: this.style,
    parentSize: this.size,
    phantom: this.phantom,
    font: this.font
  };
  for (var key in extension) {
    if (extension.hasOwnProperty(key)) {
      data[key] = extension[key];
    }
  }
  return new Options(data);
};

/**
 * Create a new options object with the given style.
 */
Options.prototype.withStyle = function (style) {
  return this.extend({
    style: style
  });
};

/**
 * Create a new options object with the given size.
 */
Options.prototype.withSize = function (size) {
  return this.extend({
    size: size
  });
};

/**
 * Create a new options object with the given color.
 */
Options.prototype.withColor = function (color) {
  return this.extend({
    color: color
  });
};

/**
 * Create a new options object with "phantom" set to true.
 */
Options.prototype.withPhantom = function () {
  return this.extend({
    phantom: true
  });
};

/**
 * Create a new options objects with the give font.
 */
Options.prototype.withFont = function (font) {
  return this.extend({
    font: font || this.font
  });
};

/**
 * Create a new options object with the same style, size, and color. This is
 * used so that parent style and size changes are handled correctly.
 */
Options.prototype.reset = function () {
  return this.extend({});
};

/**
 * A map of color names to CSS colors.
 * TODO(emily): Remove this when we have real macros
 */
var colorMap = {
  "katex-blue": "#6495ed",
  "katex-orange": "#ffa500",
  "katex-pink": "#ff00af",
  "katex-red": "#df0030",
  "katex-green": "#28ae7b",
  "katex-gray": "gray",
  "katex-purple": "#9d38bd",
  "katex-blueA": "#ccfaff",
  "katex-blueB": "#80f6ff",
  "katex-blueC": "#63d9ea",
  "katex-blueD": "#11accd",
  "katex-blueE": "#0c7f99",
  "katex-tealA": "#94fff5",
  "katex-tealB": "#26edd5",
  "katex-tealC": "#01d1c1",
  "katex-tealD": "#01a995",
  "katex-tealE": "#208170",
  "katex-greenA": "#b6ffb0",
  "katex-greenB": "#8af281",
  "katex-greenC": "#74cf70",
  "katex-greenD": "#1fab54",
  "katex-greenE": "#0d923f",
  "katex-goldA": "#ffd0a9",
  "katex-goldB": "#ffbb71",
  "katex-goldC": "#ff9c39",
  "katex-goldD": "#e07d10",
  "katex-goldE": "#a75a05",
  "katex-redA": "#fca9a9",
  "katex-redB": "#ff8482",
  "katex-redC": "#f9685d",
  "katex-redD": "#e84d39",
  "katex-redE": "#bc2612",
  "katex-maroonA": "#ffbde0",
  "katex-maroonB": "#ff92c6",
  "katex-maroonC": "#ed5fa6",
  "katex-maroonD": "#ca337c",
  "katex-maroonE": "#9e034e",
  "katex-purpleA": "#ddd7ff",
  "katex-purpleB": "#c6b9fc",
  "katex-purpleC": "#aa87ff",
  "katex-purpleD": "#7854ab",
  "katex-purpleE": "#543b78",
  "katex-mintA": "#f5f9e8",
  "katex-mintB": "#edf2df",
  "katex-mintC": "#e0e5cc",
  "katex-grayA": "#f6f7f7",
  "katex-grayB": "#f0f1f2",
  "katex-grayC": "#e3e5e6",
  "katex-grayD": "#d6d8da",
  "katex-grayE": "#babec2",
  "katex-grayF": "#888d93",
  "katex-grayG": "#626569",
  "katex-grayH": "#3b3e40",
  "katex-grayI": "#21242c",
  "katex-kaBlue": "#314453",
  "katex-kaGreen": "#71B307"
};

/**
 * Gets the CSS color of the current options object, accounting for the
 * `colorMap`.
 */
Options.prototype.getColor = function () {
  if (this.phantom) {
    return "transparent";
  } else {
    return colorMap[this.color] || this.color;
  }
};
module.exports = Options;