import { Injectable } from "@angular/core";
import { render } from "katex";

const INLINE_MATH_REGEXP = /\\\((.*?)\\\)/g;
const BLOCK_MATH_REGEXP = /\\\[(.*?)\\]/gs;

@Injectable({
  providedIn: "root",
})
export class KatexService {
  public parseMathExpressions(text: string): string {
    return text
      .replace(INLINE_MATH_REGEXP, '<span class="math">$1</span>')
      .replace(BLOCK_MATH_REGEXP, '<span class="math-block">$1</span>');
  }

  public renderMath(container: HTMLElement) {
    container.querySelectorAll<HTMLElement>(".math, .math-block").forEach((element) => {
      const formula = element.textContent || "";
      const isBlock = element.classList.contains("math-block");

      render(formula, element, { displayMode: isBlock });
    });
  }
}
