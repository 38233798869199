/**
 * This is a module for storing settings passed into KaTeX. It correctly handles
 * default settings.
 */

/**
 * Helper function for getting a default value if the value is undefined
 */
function get(option, defaultValue) {
  return option === undefined ? defaultValue : option;
}

/**
 * The main Settings object
 *
 * The current options stored are:
 *  - displayMode: Whether the expression should be typeset by default in
 *                 textstyle or displaystyle (default false)
 */
function Settings(options) {
  // allow null options
  options = options || {};
  this.displayMode = get(options.displayMode, false);
  this.throwOnError = get(options.throwOnError, true);
  this.errorColor = get(options.errorColor, "#cc0000");
  this.macros = options.macros || {};
}
module.exports = Settings;